import TrialTags from './TrialTags';
import styles from './Table.module.css';

const FIELDS = [
  'size',
  'intervention',
  'interventionB',
  'population',
  'populationB',
];

const formatDateString = (dateString) => {
  const [year, month, _] = dateString.split('-');
  const date = new Date(parseInt(year), parseInt(month) - 1);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
};

const sortByFields = (structuredSources) => {
  return structuredSources.sort((a, b) => {
    for (let field of FIELDS) {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      }
    }
    return 0;
  });
};

const dedupeAdjacent = (structuredSources) => {
  let current = {};
  return structuredSources.map((source) => {
    let result = {};
    for (let key of FIELDS) {
      if (source[key] !== current[key]) {
        result[key] = source[key];
        break;
      } else {
        result[key] = '';
      }
    }
    for (let key in source) {
      if (!result.hasOwnProperty(key)) {
        result[key] = source[key];
      }
    }
    current = source;
    return result;
  });
};

const Table = (props) => {
  let structuredSources = dedupeAdjacent(
    sortByFields(props.structured_sources)
  );
  return (
    <table className={styles.table}>
      <colgroup>
        <col className={styles.colSize} />
        <col className={styles.colIntervention} />
        <col className={styles.colComparedTo} />
        <col className={styles.colPopulation} />
        <col className={styles.colResult} />
      </colgroup>
      <thead>
        <tr>
          <td colSpan='5'>
            <TrialTags
              journal_hindex={props.journal_hindex}
              cited_by_num={props.cited_by_num}
              study_design_long={props.study_design_long}
            />
          </td>
        </tr>
        <tr className={styles.studyInfo}>
          <th colSpan='5'>
            <div className={styles.studyDateJournal}>
              {formatDateString(props.publication_date)}: <i>{props.journal}</i>
            </div>
            <div className={styles.studyAuthors}>{props.full_citation}</div>
            <div className={styles.studyTitle}>
              <a
                className='table-link'
                href={`https://pubmed.ncbi.nlm.nih.gov/${props.pmid}`}
                target='_blank'
                rel='noreferrer'
              >
                {props.title}
              </a>
            </div>
          </th>
        </tr>
        <tr className={styles.studyHeadings}>
          <th>Size</th>
          <th>Intervention</th>
          <th>Compared to</th>
          <th>Population</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {structuredSources.map((source, index) => (
          <tr
            key={`${index}-${props.pmid}-${source.result_verb.replace(
              ' ',
              '-'
            )}-${source.variable.replace(' ', '-')}`}
          >
            <td>{source.size === -1 ? '?' : source.size}</td>
            <td>{source.intervention}</td>
            <td>{source.interventionB}</td>
            <td>{source.population}</td>
            <td>
              {source.result_verb} {source.variable}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;

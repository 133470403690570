/**
 * A JSON object persisted in localStorage containing the id and the timestamp
 * when it was saved. This is no longer needed now that we're using Mixpanel,
 * but kept for backwards-compatibility.
 */
export interface ExternalIdentifierRecord {
  idValue: string;
  timestamp: number;
}

/**
 * Given a value read from localStorage, extracts the ID from an
 * ExternalIdentifierRecord if necessary. Needed for backwards-compatibility.
 */
export const parseLocalStorageRecord = (value?: string): string | undefined => {
  // Assume if it starts with '{', it's a JSON object.
  if (!value || value[0] !== '{') {
    return value;
  }
  try {
    const record: ExternalIdentifierRecord = JSON.parse(value);
    return record.idValue;
  } catch (error) {
    console.error('Error parsing JSON from localStorage', error);
  }
};

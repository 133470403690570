import Section from './Section';
import TableOfContents from './TableOfContents';
import styles from './Bibliography.module.css';

const sectionsToResultsMessage = (sections) => {
  let trials = new Set();
  for (let section of sections) {
    for (let category of section.categories) {
      for (let trial of category.trials) {
        trials.add(trial.pmid);
      }
    }
  }

  if (trials.size === 1) {
    return 'Found 1 matching clinical trial.';
  } else {
    return `Found ${trials.size} matching clinical trials.`;
  }
};

const Bibliography = (props) => {
  return (
    <div className={styles.bibliography}>
      {props.sections !== undefined && !props.in_article_version && (
        <div className={styles.bibliographyResultsMessage}>
          {props.sections.length === 0 && 'No matching clinical trials.'}
          {props.sections.length > 0 &&
            sectionsToResultsMessage(props.sections)}
        </div>
      )}
      {props.in_article_version && (
        <div className={styles.bibliographyInArticleVersionHeader}></div>
      )}
      {props.sections !== undefined &&
        props.sections.length > 0 &&
        props.sections[0].categories.length > 0 &&
        props.sections[0].categories[0].name.length > 0 && (
          <TableOfContents sections={props.sections} />
        )}
      {props.sections !== undefined &&
        props.sections.map((section) => (
          <Section
            key={section.name.toLowerCase().replace(' ', '-')}
            {...section}
          />
        ))}
    </div>
  );
};

export default Bibliography;

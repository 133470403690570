import * as Sentry from '@sentry/browser';
import { Fragment } from 'react';
import Collapsible from 'react-collapsible';
import parse from 'html-react-parser';
import Latex from 'react-latex-next';

// citations functionality
import { ct_to_citation_string, create_citation_span } from '../citations';
import mode from '../mode';
import type { ArticleSpan, Citation } from '../types';

// Get specific article component possibilities
import InterventionConditionFindingsSummary from './article_components/InterventionConditionFindingsSummary';
import InterventionConditionAdverseEffectsSummary from './article_components/InterventionConditionAdverseEffectsSummary';
import renderConditionMarqueeFigure from './article_components/ConditionMarqueeFigure';
import KeyFindingsInfobox from './article_components/KeyFindingsInfobox';
import renderTreatmentGuidelines from './article_components/TreatmentGuidelines';
import { VerticalBarChart } from './article_components/VerticalBarChart';
import { ArticleTable } from './article_components/ArticleTable';
import renderInterventionConditionEffectSizeFigure from './article_components/InterventionConditionEffectSizeFigure';
import ArticleReview from './article_components/ArticleReview';
import { SxProps, Typography } from '@mui/material';
import EmbeddedTweet from './article_components/EmbeddedTweet';
import EmbeddedLinkedIn from './article_components/EmbeddedLinkedIn';
import EmbeddedYouTube from './article_components/EmbeddedYouTube';
import { AgentIteration } from './article_components/AgentIteration';
import BlockQuote from './article_components/BlockQuote';

import { useArticleContext } from '../contexts';

import 'katex/dist/katex.min.css';

// Regular expression to match LaTeX equations
const LATEX_EQUATION = new RegExp(/\\\[.*?\\\]|\\\(.*?\\\)/, 'm');

interface ArticleComponentProps {
  data: any;
  styles: Record<string, string>;
  conjoined_page?: boolean;
  conjoined_page_first_page?: boolean;
  [key: string]: any;
}

export type RenderFunctionFilter = (
  _: ArticleComponentProps
) => JSX.Element | null;

const COMPONENT_RENDER_FNS: Record<string, RenderFunctionFilter> = {
  InterventionConditionFindingsSummary: (props) => (
    <InterventionConditionFindingsSummary {...props} />
  ),
  InterventionConditionEffectSizeFigure:
    renderInterventionConditionEffectSizeFigure,
  ConditionMarqueeFigure: renderConditionMarqueeFigure,
  InterventionConditionAdverseEffectsSummary: (props) => (
    <InterventionConditionAdverseEffectsSummary {...props} />
  ),
  KeyFindingsInfobox: (props) => <KeyFindingsInfobox {...props} />,
  TreatmentGuidelines: renderTreatmentGuidelines,
  VerticalBarChart: (props) => <VerticalBarChart {...props} />,
  Figure: (props) => <VerticalBarChart {...props} />,
  Table: (props) => <ArticleTable {...props} />,
  ArticleReview: (props) => <ArticleReview {...props} />,
  EmbeddedTweet: (props) => <EmbeddedTweet {...props} />,
  EmbeddedLinkedIn: (props) => <EmbeddedLinkedIn {...props} />,
  EmbeddedYouTube: (props) => <EmbeddedYouTube {...props} />,
  AgentIteration: (props) => <AgentIteration {...props} />,
  BlockQuote: (props) => <BlockQuote {...props.data} />,
};

type ComponentRenderMapKey = keyof typeof COMPONENT_RENDER_FNS;

const renderArticleComponent = (
  articleSpan: ArticleSpan,
  key: string,
  // Additional render functions provided by the caller
  additionalRenderFunctions: Record<string, RenderFunctionFilter>,
  // Additional key,value pairs that are computed in the
  // article scope, but are needed in the component (eg)
  // citations.
  additional_component_data: Record<string, unknown>,
  styles: Record<string, string>
): JSX.Element | null => {
  let span_components = articleSpan.text.split('!:!');
  let component_name = span_components[1];

  let component_render_fns = {
    ...COMPONENT_RENDER_FNS,
    ...additionalRenderFunctions,
  };

  if (!(component_name in component_render_fns)) {
    return <span>Unknown component name: {component_name}</span>;
  }

  try {
    let component_data = JSON.parse(span_components[2]);

    // Insert additional data into the component data
    for (let key in additional_component_data) {
      if (additional_component_data.hasOwnProperty(key)) {
        let value = additional_component_data[key];
        component_data[key] = value;
      }
    }

    const renderer =
      component_render_fns[component_name as ComponentRenderMapKey];
    const element = renderer({
      data: component_data,
      styles,
    });

    return element === null ? null : (
      // attach a key using a Fragment so that the underlying JSX does not have to
      <Fragment key={key}>{element}</Fragment>
    );
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

// TODO(danny): consolidate with TextSpanProps
interface RenderSpanProps {
  additionalRenderFunctions: Record<string, RenderFunctionFilter>;
  additionalComponentData: Record<string, unknown>;
  firstTextSpanFound?: { found: boolean };
  articleSpan: ArticleSpan;
  references: Citation[];
  prefix: string;
  questionIndex?: number;
  styles: Record<string, string>;
  displayDisagree: boolean;
  disableCitationLinks: boolean;
  smallVersion?: boolean;
  cursorSxFor?: (_location: string) => SxProps;
}

export const RenderSpan = ({
  articleSpan,
  references,
  prefix,
  styles,
  displayDisagree,
  disableCitationLinks,
  additionalRenderFunctions,
  additionalComponentData,
  questionIndex,
  firstTextSpanFound = undefined,
  smallVersion = false,
  cursorSxFor = (_location: string) => ({} as SxProps),
}: RenderSpanProps): JSX.Element | null => {
  const { askWrapperContainerId } = useArticleContext();

  // Handle the case where the span is the warning span
  if (articleSpan.text.includes('class="pop_condition_warnings')) {
    return (
      <WarningsSectionCollapsible text={articleSpan.text} prefix={prefix} />
    );
  }

  // Always get citations that are explicitly given first
  const citations = [...articleSpan.citations];

  // For each citation, 0 = normal, 1 = disagree display
  const citation_disagrees = Array(citations.length).fill(0);

  // Then add citations from the data if in citation mode
  const outcomes = [];
  for (let articleSpanEntry of articleSpan.articlespanentry_set) {
    const ct =
      articleSpanEntry.clinical_report ?? articleSpanEntry.clinical_trial;
    const citation_string = ct_to_citation_string(ct);
    citations.push(citation_string);
    outcomes.push(articleSpanEntry.outcome);
  }

  // Set the disagreeing citations from the data
  const most_common_outcome = mode(outcomes);
  for (let outcome of outcomes) {
    citation_disagrees.push(
      outcome !== most_common_outcome && outcome !== 'Mixed'
    );
  }

  // Handle the case where the span represents the data for a react component
  if (articleSpan.text.includes('REACTCOMPONENT')) {
    const { element: citation_span } = create_citation_span(
      citations,
      citation_disagrees,
      references, // gets mutated!
      prefix,
      styles,
      displayDisagree,
      disableCitationLinks,
      questionIndex,
      askWrapperContainerId
    );

    const additional_component_data = {
      citation_data: {
        referencesArr: references,
        full_citation_span: citation_span,
      },
      questionIndex,
      askWrapperContainerId,
      ...additionalComponentData,
    };

    // Actually build the specific component
    return renderArticleComponent(
      articleSpan,
      prefix,
      additionalRenderFunctions,
      additional_component_data,
      styles
    );
  }

  // If we're in small version mode, and we've already found a text span, then
  // we don't want to render any more text spans.
  if (smallVersion && firstTextSpanFound && firstTextSpanFound.found) {
    return null;
  }

  /*
  NB (andy): `citations` is the list of available sources, while `references`
  is the list that gets displayed in the UI. `references` array is mutated when
  the <TextSpan> renders with a citation (e.g. [1])
  */
  const textSpanElement = renderTextSpan({
    articleSpan,
    citations,
    citation_disagrees,
    references, // gets mutated!
    prefix,
    styles,
    displayDisagree,
    disableCitationLinks,
    questionIndex,
    smallVersion,
    cursorSxFor,
    askWrapperContainerId,
  });

  // Now the span is just a text span, we need to note that to be able to
  // correctly handle the "smallVersion" case
  if (firstTextSpanFound) {
    firstTextSpanFound.found = true;
  }

  return textSpanElement;
};

interface WarningsSectionCollapsibleProps {
  text: string;
  prefix: string;
}
function WarningsSectionCollapsible({
  text,
  prefix,
}: WarningsSectionCollapsibleProps) {
  return (
    <Collapsible
      classParentString='pop_condition_warnings'
      trigger='FDA Warnings'
      key={`${prefix}_warning`}
    >
      {parse(text)}
    </Collapsible>
  );
}

interface TextSpanProps {
  articleSpan: ArticleSpan;
  citations: Citation[];
  citation_disagrees: (0 | 1)[];
  references: Citation[];
  prefix: string;
  styles: Record<string, string>;
  displayDisagree: boolean;
  disableCitationLinks: boolean;
  questionIndex?: number;
  smallVersion?: boolean;
  cursorSxFor?: (_location: string) => SxProps;
  className?: string;
  askWrapperContainerId?: string;
}

function TextSpan({
  articleSpan,
  styles,
  citations,
  citation_disagrees,
  references,
  prefix,
  displayDisagree,
  disableCitationLinks,
  questionIndex,
  askWrapperContainerId,
  smallVersion = false,
  cursorSxFor = (_location: string) => ({} as SxProps),
}: TextSpanProps) {
  let { element: citationElement } = create_citation_span(
    citations,
    citation_disagrees,
    references, // gets mutated!
    prefix,
    styles,
    displayDisagree,
    disableCitationLinks || articleSpan.hidden,
    questionIndex,
    askWrapperContainerId
  );

  // If we're in small version mode, we add an extra class to the span. Given that we only
  // reach here if the first text span has not been found, we know that this is the first
  const addFadeStyle = smallVersion;
  const spanClasses = [
    addFadeStyle ? styles.fade : undefined,
    articleSpan.is_block_quote ? styles.block_quote : undefined,
    articleSpan.is_highlighted ? styles.bold_text : undefined,
  ].filter(Boolean);
  const spanClassString =
    spanClasses.length > 0 ? spanClasses.join(' ') : undefined;

  const spanSx = {
    ...cursorSxFor('text'),
    ...cursorSxFor('citations'),
  };

  // Render bullet points rather than markdown symbols for unordered list items
  const text = articleSpan.text.replace(/^(\s*)[-+*]/, '$1•');
  let parsed = parse(text);

  if (typeof parsed === 'string' && LATEX_EQUATION.test(parsed)) {
    parsed = <Latex>{parsed}</Latex>;
  }

  if (Array.isArray(parsed)) {
    parsed = parsed.map((element, index) => {
      if (typeof element === 'string' && LATEX_EQUATION.test(element)) {
        return <Latex key={index}>{element}</Latex>;
      }
      return element;
    });
  }

  return (
    <Typography
      component='span'
      variant='serif'
      className={spanClassString}
      sx={spanSx}
    >
      <span>{parsed}</span>
      {citationElement}
    </Typography>
  );
}

// It's possible to have a span that only has metadata and no actual content
// (where content = text or citations). In this case, we don't want to render
// the empty span which can cause weird spacing issues.
function renderTextSpan(textSpanProps: TextSpanProps) {
  const { articleSpan, citations } = textSpanProps;

  if ((citations.length === 0 && !articleSpan.text) || articleSpan.hidden) {
    return null;
  }
  return <TextSpan key={textSpanProps.prefix} {...textSpanProps} />;
}

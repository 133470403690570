import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useArticleContext } from './contexts';
import { TranslateFunction, useTranslation } from '@xyla/util';

export interface Step {
  name: string;
  minSeconds: number;
  maxSeconds: number;
}

export const DEFAULT_STEPS: (t: TranslateFunction) => Step[] = (t) => [
  {
    name: t('Analyzing query'),
    minSeconds: 2,
    maxSeconds: 4,
  },
  {
    name: t('Retrieving sources'),
    minSeconds: 3,
    maxSeconds: 5,
  },
  {
    name: t('Synthesizing relevant information'),
    minSeconds: 7,
    maxSeconds: 9,
  },
  {
    name: t('Summarizing key findings'),
    minSeconds: 5,
    maxSeconds: 10,
  },
  {
    name: t('Still working'),
    minSeconds: 999999999,
    maxSeconds: 999999999,
  },
];

interface ArticleLoadingStateProps {
  steps?: Step[];
  collapsed?: boolean;
}

export default function ArticlePlaceholder({
  steps,
  collapsed,
}: ArticleLoadingStateProps) {
  const { customLoadingSpinner, customLoadingSteps } = useArticleContext();
  const [currentStepString, setCurrentStepString] = useState('');
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const stepIndex = useRef(0);
  const nextStepAt = useRef(0);

  const localizeString = useTranslation();
  const stepsToUse =
    steps || customLoadingSteps || DEFAULT_STEPS(localizeString);

  // Initially, start the timer
  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsElapsed((secondsElapsed) => secondsElapsed + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [setSecondsElapsed]);

  // When the timer changes, check if we need to move to the next step
  useEffect(() => {
    if (
      nextStepAt.current <= secondsElapsed &&
      stepIndex.current < stepsToUse.length
    ) {
      // Set current step
      setCurrentStepString(stepsToUse[stepIndex.current].name);

      // Set next duration
      const nextStepDurationMin = stepsToUse[stepIndex.current].minSeconds;
      const nextStepDurationMax = stepsToUse[stepIndex.current].maxSeconds;
      const nextStepDuration = Math.floor(
        Math.random() * (nextStepDurationMax - nextStepDurationMin) +
          nextStepDurationMin
      );
      nextStepAt.current = secondsElapsed + nextStepDuration;

      // Set next index
      stepIndex.current = stepIndex.current + 1;
    }
  }, [secondsElapsed, setCurrentStepString, stepsToUse]);

  const currentStepStringToShow = currentStepString
    ? `${currentStepString}...`
    : '';

  if (collapsed) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            mb: 2,
          }}
        >
          {customLoadingSpinner || (
            <CircularProgress
              color='inherit'
              sx={{ color: 'grey.500' }}
              size={'1.25em'}
            />
          )}
          <Typography variant='body2'>{currentStepStringToShow}</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 200,
          flexDirection: 'column',
        }}
      >
        {customLoadingSpinner || (
          <CircularProgress
            color='inherit'
            sx={{ color: 'grey.500' }}
            size={32}
          />
        )}
        <Typography mt={2}>{currentStepStringToShow}</Typography>
      </Box>
    );
  }
}

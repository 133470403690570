import { Box, SxProps, Theme, Typography } from '@mui/material';

export interface HeaderProps {
  icon: React.ReactNode;
  title: string;
  sx?: SxProps<Theme>;
}

export const Header = ({ icon, title, sx = {} }: HeaderProps) => (
  <Box
    sx={[
      {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'row',
        gap: 1,
        padding: 2,
        alignItems: 'center',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {icon}
    <Typography
      sx={{
        fontSize: '1.125rem',
      }}
    >
      {title}
    </Typography>
  </Box>
);

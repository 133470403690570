import { TwitterEmbed } from 'react-social-media-embed';

interface EmbeddedTweetProps {
  data: {
    tweetUrl: string;
  };
}

export default function EmbeddedTweet({ data }: EmbeddedTweetProps) {
  // see https://github.com/justinmahar/react-social-media-embed#twitter
  if (!data.tweetUrl.includes('status')) {
    throw new Error('needs to be a full url');
  }
  return <TwitterEmbed key={data.tweetUrl} url={data.tweetUrl} />;
}

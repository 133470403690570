import { Stack, Typography } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './shared/accordion-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Search, CreateOutlined } from '@mui/icons-material';

function formatString(
  searchString: string,
  formatObject: Record<string, string>
): string {
  const regex = /%(\w+)/g;

  const replacedString = searchString.replace(regex, (match, key) => {
    const replacement = formatObject[key];
    return replacement !== undefined ? replacement : match;
  });

  return replacedString;
}

interface AgentIterationProps {
  plan: string;
  tools: any;
  citation_data: any;
}

export const AgentIteration = ({
  data,
}: {
  data: AgentIterationProps;
  styles: any;
}) => {
  const { plan, tools } = data;
  console.log(tools);

  if (!tools || !tools[0])
    return (
      <>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CreateOutlined />
            <Typography
              sx={{ fontStyle: 'italic' }}
            >{`Writing answer...`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{plan}</Typography>
          </AccordionDetails>
        </Accordion>
      </>
    );

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Search />
          <Stack spacing={2}>
            {tools.map((tool: any) => (
              <Typography sx={{ fontStyle: 'italic' }}>
                {`${formatString(tool.display_info ?? '', tool.kwargs)}`}
              </Typography>
            ))}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{plan}</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

import { useCallback, useContext } from 'react';
import LoadingContext, { RenderPlaceholdersContext } from './contexts';

const useLoadingContainer = () => {
  const setIsLoading = useContext(LoadingContext);
  const renderPlaceholder = useContext(RenderPlaceholdersContext);

  const markAsLoaded = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const markAsUnloaded = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  return { markAsUnloaded, markAsLoaded, renderPlaceholder };
};

export default useLoadingContainer;

import { alpha, createTheme, Theme, ThemeOptions } from '@mui/material/styles';

// Default theme tokens
export const PFIZER_PRIMARY_COLOR = '#3e96f7';
export const PFIZER_SECONDARY_COLOR = '#0e00c9';
const PFIZER_SANS = [
  'Nexus Sans',
  'Schibsted Grotesk',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');

/** Tokens used to define the PFIZER MUI theme */
interface ThemeTokens {
  primaryColor?: string;
  secondaryColor?: string;
  fontFamilySans?: string;
  fontFamilySerif?: string;
}

// https://mui.com/material-ui/customization/typography/#variants
// Tell TS about custom variants when creating the theme
declare module '@mui/material/styles' {
  interface TypographyVariants {
    serif: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    serif?: React.CSSProperties;
  }
}
// Tell TS that our custom variant is valid in Typography components
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    serif: true;
  }
}

interface ThemeFactoryProps {
  tokens?: ThemeTokens;
  overrides?: ThemeOptions;
}

/**
 * Creates an MUI theme with optional overrides. Default theme uses PFIZER styles.
 *
 * @param tokens - high-level values to configure the theme globally, such as colors and fonts
 * @param overrides - any MUI `Theme` properties to override PFIZER styles
 */
export function createPfizerTheme(props?: ThemeFactoryProps): Theme {
  const { tokens, overrides } = props ?? {};

  // Fill in default values
  const concreteTokens: Required<ThemeTokens> = {
    primaryColor: PFIZER_PRIMARY_COLOR,
    secondaryColor: PFIZER_SECONDARY_COLOR,
    fontFamilySans: PFIZER_SANS,
    // NB(andy): This is intentional to match Elsevier sans usage
    fontFamilySerif: PFIZER_SANS,
    ...tokens,
  };

  return createTheme({
    palette: {
      primary: {
        main: concreteTokens.primaryColor,
        light: alpha(concreteTokens.primaryColor, 0.7),
      },
      secondary: {
        main: concreteTokens.secondaryColor,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderRadius: 0,
            fontSize: '1rem',
            textTransform: 'capitalize',
            ...(ownerState.variant === 'contained' && {
              '&:hover': {
                backgroundColor: concreteTokens.secondaryColor,
              },
            }),
          }),
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: () => ({
            borderRadius: 0,
            boxShadow: 'none',
            border: '1px solid #cecece',
            marginLeft: '-1px',
            '@media (max-width: 800px)': {
              marginLeft: '-6px',
            },
          }),
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: () => ({
            paddingTop: '2px',
          }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            border: '1px solid #969696',
            borderTop: `2px solid ${concreteTokens.secondaryColor}`,
            borderRadius: 0,
            boxShadow: `0 0 0 1rem hsla(0,0%,92%,.85)`,
          },
        },
      },
    },
    typography: {
      fontFamily: concreteTokens.fontFamilySans,
      serif: {
        fontFamily: concreteTokens.fontFamilySerif,
      },
      h1: {
        fontSize: '4.25rem',
        fontWeight: 500,
        letterSpacing: '-0.022em',
        textAlign: 'center',
        margin: '0.625em 0',
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 500,
      },
    },
    // TODO(andy): Try to refactor out. The only difference from MUI defaults is sm:600->750.
    breakpoints: {
      values: {
        xs: 0,
        sm: 750,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    // Apply any overrides if provided
    ...overrides,
  });
}

// Specific page type formatting. Needs to come after default styles so it takes precedence
import { combineStyles } from '../utils';
import condition_page_styles from './condition_page.module.css';
import condition_subpage_styles from './condition_subpage.module.css';
import science_writer_article_styles from './science_writer_article_styles.module.css';
import ask_oe_styles from './ask_oe.module.css';
import ask_oe_light_styles from './ask_oe_light.module.css';
import ask_oe_light_with_citations_styles from './ask_oe_light_with_citations.module.css';

const page_type_to_styles: Record<string, Record<string, string>> = {
  condition_subpage: condition_subpage_styles,
  'Science Writer Article': science_writer_article_styles,
  'PageType.OTHER': condition_page_styles,
  ask_oe: ask_oe_styles,
  ask_oe_light: combineStyles(ask_oe_styles, ask_oe_light_styles),
  ask_oe_light_with_citations: combineStyles(
    ask_oe_styles,
    ask_oe_light_with_citations_styles
  ),
  article_card: combineStyles(
    ask_oe_styles,
    ask_oe_light_with_citations_styles
  ),
  search_results: combineStyles(
    ask_oe_styles,
    ask_oe_light_with_citations_styles
  ),
  ask_finance: combineStyles(ask_oe_styles, ask_oe_light_with_citations_styles),
};

export const get_combined_styles = (
  existing_style: { [key: string]: string },
  page_type: string
) => {
  const new_styles = page_type_to_styles[page_type];
  if (!new_styles) {
    return existing_style;
  }
  return combineStyles(existing_style, new_styles);
};

import { SkeletonProps } from '@mui/material';
import { JSXElementConstructor, useState } from 'react';
import DefaultComponent from './DefaultSkeletonBase';
import FloatingSkeleton from './FloatingSkeleton';
import LoadingContext from './contexts';

type LoadingContainerProps = {
  Component?: JSXElementConstructor<any>;
} & SkeletonProps;

const LoadingContainer = ({
  Component = DefaultComponent,
  ...passthrough
}: LoadingContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <LoadingContext.Provider value={setIsLoading}>
      <FloatingSkeleton
        Component={Component}
        {...passthrough}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </LoadingContext.Provider>
  );
};

export default LoadingContainer;

import React from 'react';
import { Box, Typography, Grow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { useArticleContext } from '../../../contexts';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import { ArticleData, ArticleSection } from '../../../types';
import { DEFAULT_ARTICLE_DATA } from '../../../utils';
import DownloadButton from './DownloadButton';
import { PrintButton } from './PrintButton';

const BORDER_RADIUS = '8px';
const GROW_TIMEOUT_MS = 300;

export interface DocumentPreviewProps {
  children: React.ReactNode;
  sectionData: ArticleSection;
  initiallyExpanded?: boolean;
}

export function DocumentPreview({
  children,
  sectionData,
}: DocumentPreviewProps) {
  const { palette } = useTheme();
  const { isTyping } = useArticleContext();

  const title = sectionData.section_title;
  const fileName = sectionData.metadata?.file ?? 'download';

  const documentArticleData: ArticleData = {
    ...DEFAULT_ARTICLE_DATA,
    articlesection_set: [sectionData],
  };

  return (
    <Grow in appear={isTyping} timeout={GROW_TIMEOUT_MS}>
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: palette.grey[50],
          borderRadius: BORDER_RADIUS,
          border: `1px solid ${palette.grey[300]}`,
          fontSize: '.875rem',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'grey.200',
            color: 'grey.1000',
            gap: 1,
            padding: 1,
            alignItems: 'center',
            borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
            '&:hover': {
              backgroundColor: palette.grey[200],
            },
          }}
        >
          <ArticleIcon
            sx={{
              fontSize: '2.25rem',
            }}
          />
          <Typography noWrap>{title}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
          >
            <CopyToClipboardButton article={documentArticleData} />
            <DownloadButton article={documentArticleData} fileName={fileName} />
            <PrintButton article={documentArticleData} fileName={fileName} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            fontSize: '.875rem',
          }}
        >
          {children}
        </Box>
      </Box>
    </Grow>
  );
}

'use client';
import {
  Skeleton as MuiSkeleton,
  SkeletonProps,
  SkeletonTypeMap,
  SxProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { JSXElementConstructor } from 'react';
import { RenderPlaceholdersContext } from './contexts';

const FullHeightSkeleton = styled(MuiSkeleton)({
  transform: 'none',
  maxWidth: 'none',
});

interface FloatingSkeletonProps extends SkeletonProps {
  Component: JSXElementConstructor<any>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const HIDDEN_STYLES: SxProps = {
  position: 'absolute !important',
  visibility: 'hidden !important',
};

const FloatingSkeleton = ({
  Component,
  isLoading,
  setIsLoading,
  ...passthrough
}: FloatingSkeletonProps) => {
  const TypedSkeleton = FullHeightSkeleton as OverridableComponent<
    SkeletonTypeMap<typeof passthrough, typeof Component>
  >;

  const { children, ...containerProps } = passthrough;
  const hideIfLoading = isLoading
    ? {
        sx: {
          ...passthrough.sx,
          ...HIDDEN_STYLES,
        } as SxProps,
      }
    : {};

  // disable ts for the next line
  // @ts-ignore
  return (
    <>
      {isLoading && (
        <RenderPlaceholdersContext.Provider value={true}>
          <TypedSkeleton component={Component as any} {...passthrough} />
        </RenderPlaceholdersContext.Provider>
      )}
      <Component {...containerProps} {...hideIfLoading}>
        {children}
      </Component>
    </>
  );
};

export default FloatingSkeleton;

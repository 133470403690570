'use client';

import { useCallback, useEffect } from 'react';

interface UseTimerWorkersOutput {
  setTimeoutWorker: (callback: () => void, ms: number) => number;
  clearTimeoutWorker: (id: number) => void;
  setIntervalWorker: (callback: () => void, ms: number) => number;
  clearIntervalWorker: (id: number) => void;
}

interface WorkerMessage {
  id: number;
  type: 'interval' | 'timeout';
}

let worker: Worker | undefined = undefined;
const timerIdToCallbackMap: Record<number, () => void> = {};

/**
 * React hook that creates timers using a web worker to avoid browser background tab throttling
 * See https://stackoverflow.com/questions/6032429/chrome-timeouts-interval-suspended-in-background-tabs
 * and https://github.com/turuslan/HackTimer.
 *
 * Defaults to native timers if workers are not available
 *
 * @returns alternative functions to native setTimeout, clearTimeout, setInterval, and clearInterval:
 * setTimeoutWorker, clearTimeoutWorker, setIntervalWorker, clearIntervalWorker.
 */
export const useTimerWorkers = (): UseTimerWorkersOutput => {
  useEffect(() => {
    if (worker === undefined && typeof Worker !== 'undefined') {
      worker = new Worker(new URL('./worker.ts', import.meta.url));
      // listen for messages from the background worker once its timer expires
      // and execute the associated callback
      worker.onmessage = (e: MessageEvent<WorkerMessage>) => {
        const callback = timerIdToCallbackMap[e.data.id];
        if (callback !== undefined) {
          if (e.data.type === 'timeout') {
            delete timerIdToCallbackMap[e.data.id];
          }
          callback();
        }
      };
    }
  }, []);

  const setTimeoutWorker = useCallback((callback: () => void, ms: number) => {
    const id = Date.now();
    timerIdToCallbackMap[id] = callback;
    worker?.postMessage({
      name: 'setTimeout',
      time: ms,
      id,
    });
    return id;
  }, []);

  const clearTimeoutWorker = useCallback((id: number) => {
    worker?.postMessage({
      name: 'clearTimeout',
      id,
    });
    delete timerIdToCallbackMap[id];
    return;
  }, []);

  const setIntervalWorker = useCallback((callback: () => void, ms: number) => {
    const id = Date.now();
    timerIdToCallbackMap[id] = callback;
    worker?.postMessage({
      name: 'setInterval',
      time: ms,
      id,
    });
    return id;
  }, []);

  const clearIntervalWorker = useCallback((id: number) => {
    worker?.postMessage({
      name: 'clearInterval',
      id,
    });
    delete timerIdToCallbackMap[id];
    return;
  }, []);

  if (typeof Worker === 'undefined') {
    return {
      setTimeoutWorker: setTimeout,
      clearTimeoutWorker: clearTimeout,
      setIntervalWorker: setInterval,
      clearIntervalWorker: clearInterval,
    };
  }

  return {
    setTimeoutWorker,
    clearTimeoutWorker,
    setIntervalWorker,
    clearIntervalWorker,
  };
};

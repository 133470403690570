import { styled } from '@mui/material/styles';
import { ComponentProps, JSXElementConstructor } from 'react';

const DefaultSkeletonBase = styled('div')({});
export type DefaultSkeletonBaseProps = ComponentProps<
  typeof DefaultSkeletonBase
>;
export type DefaultSkeletonConstructor =
  JSXElementConstructor<DefaultSkeletonBaseProps>;

export default DefaultSkeletonBase;

import { LinkedInEmbed, LinkedInEmbedProps } from 'react-social-media-embed';

interface EmbeddedLinkedInProps {
  data: LinkedInEmbedProps;
}

export default function EmbeddedLinkedIn({ data }: EmbeddedLinkedInProps) {
  // see https://github.com/justinmahar/react-social-media-embed#linkedin
  return <LinkedInEmbed key={data.url} {...data} />;
}

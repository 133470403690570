import { useState } from 'react';
import {
  Box,
  Fade,
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useArticleContext } from '../contexts';
import { AnswerEngagedExternalLinkListOpenedEventProps } from '@xyla/analytics';
import { LocalizedText } from '@xyla/util';
import { ExternalSearchResult } from '../types';
import { SearchLink } from './SearchLink';

interface ArticleReferencesProps {
  results: ExternalSearchResult[];
  startExpanded?: boolean;
  maxLinksCollapsed?: number;
  maxLinks?: number;
}

export function WebSearchResults({
  results,
  startExpanded = false,
  maxLinks,
  maxLinksCollapsed = 3,
}: ArticleReferencesProps): JSX.Element | null {
  const { trackArticleEvent } = useArticleContext();
  const maxLinksExpanded = maxLinks ?? results.length;
  const [numLinksToShow, setNumLinksToShow] = useState(
    startExpanded ? maxLinksExpanded : maxLinksCollapsed
  );

  const isExpanded = numLinksToShow > maxLinksCollapsed;
  const isCollapsedMinimized = maxLinksCollapsed === 0;

  const handleChange = () => {
    if (isExpanded) {
      trackArticleEvent?.('answer_engaged', {
        action: 'External links list opened',
      } satisfies AnswerEngagedExternalLinkListOpenedEventProps);
    }
    setNumLinksToShow(isExpanded ? maxLinksCollapsed : maxLinksExpanded);
  };

  if (!results || results.length === 0) {
    return null;
  }

  const collapseButton = (
    <IconButton
      onClick={handleChange}
      sx={{
        width: '30px',
        height: '30px',
        marginLeft: 'auto',
      }}
      disableRipple
    >
      <ExpandMoreIcon
        sx={{
          transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.2s ease-in-out',
        }}
      />
    </IconButton>
  );

  return (
    <Paper
      elevation={0}
      className={'brandable--follow-up-questions'}
      sx={{
        backgroundColor: 'hwb(0 96% 4.5%)', // Slightly darker
        border: '1px solid hwb(0 96% 10%)',
        borderRadius: '24px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          textAlign: 'left',
          padding:
            isCollapsedMinimized && !isExpanded
              ? { xs: '1em 1em 0em 1em' }
              : { xs: '1.5em 1.5em 0.5em 1.5em', sm: '2em 2em 0.5em 2em' },
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
        }}
      >
        <Box
          className='brandable--follow-up-questions--title-bar'
          sx={{ display: 'flex' }}
        >
          <Tooltip title='These external sources may be relevant to your question. They are not used in the answer.'>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <SearchIcon fontSize='small' />

              <Typography
                sx={{ fontSize: '1em', fontWeight: 500 }}
                variant='h2'
              >
                <LocalizedText str='Web Search Results' />
              </Typography>
              <InfoIcon fontSize='inherit' />
            </Box>
          </Tooltip>
          {!isExpanded && maxLinksCollapsed === 0 && collapseButton}
        </Box>
        <div>
          {results.slice(0, numLinksToShow).map((searchResult, i) => (
            <Fade in timeout={500}>
              <Box key={i}>
                <SearchLink
                  description={searchResult.snippet}
                  url={searchResult.url}
                  siteName={searchResult.siteName}
                  title={searchResult.name}
                  chip={'web'}
                  maxDescriptionLines={2}
                />
                {i < numLinksToShow - 1 && i < results.length - 1 && (
                  <Divider />
                )}
              </Box>
            </Fade>
          ))}
        </div>
        {(maxLinksCollapsed > 0 || isExpanded) &&
          results.length > maxLinksCollapsed && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
              }}
            >
              {collapseButton}
            </Box>
          )}
      </Box>
    </Paper>
  );
}

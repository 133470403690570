import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters={true} {...props} />
))(() => ({
  '&.MuiAccordion-root': {
    border: 'none',
    borderTop: '1px solid rgb(0 0 0 / 0.12)',
    borderRadius: 0,
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`,
  '.MuiTypography-root': {
    marginLeft: theme.spacing(2),
    fontSize: '18px',
  },
}));

export const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  padding: 0,
}));

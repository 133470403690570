import { Callout } from './Callout/Callout';
import { CalloutCitedDocument } from './Callout/types';
import MedicationOutlined from '@mui/icons-material/MedicationOutlined';
import VaccinesOutlined from '@mui/icons-material/VaccinesOutlined';

export interface DrugsSectionProps {
  children: React.ReactNode;
  section_title: string;
  citation?: string;
  documents?: CalloutCitedDocument[];
  variant?: 'drugs' | 'vaccines';
}

export const DrugsSection = ({
  children,
  section_title,
  citation,
  documents,
  variant,
}: DrugsSectionProps) => {
  // when in doubt, use the drugs icon
  const icon =
    variant === 'vaccines' ? <VaccinesOutlined /> : <MedicationOutlined />;

  // ditto
  const documentType = variant === 'vaccines' ? 'Vaccine' : 'Drug';
  const documentDetailsListPreviewText = (documents: CalloutCitedDocument[]) =>
    `${documents.length} ${documentType} Label${
      documents.length > 1 ? 's' : ''
    }`;

  return (
    <Callout
      title={section_title}
      icon={icon}
      citation={citation}
      documents={documents}
      documentDetailsListPreviewText={documentDetailsListPreviewText}
    >
      {children}
    </Callout>
  );
};

import converter from 'number-to-words';
import { titleCase } from 'title-case';

import { COLORS } from '@xyla/style';
import LoadingContainer from '../../LoadingContainer';
import { usePlotlyContext, useLinkContext } from '../../contexts';

import styles from './InterventionConditionFindingsSummary.module.css';
import LivingReviewTag from './LivingReviewTag';

const arc_path = (x0, y0, r, start_angle, end_angle, N, x_over_y) => {
  // Note: x_over_y is needed because for some reason the "paper" reference for the errorbar
  // annotation is not the same as the perfect circle for the pie chart. The pie chart is
  // always a perfect circle, but the "paper" may not end up being exactly equal on the x and y
  // sides. Drawing the errorbars as a perfect circle needs a corrective factor applied to the x
  // values. This factor is `x_over_y`.

  let path = '';

  // Make the paths for the arc
  for (let t = 0; t < N + 1; t++) {
    let angle_degrees = (t / N) * (end_angle - start_angle) + start_angle;
    let angle_rad = (angle_degrees * 2 * Math.PI) / 360;
    let x = x0 + r * x_over_y * Math.cos(angle_rad);
    let y = y0 + r * Math.sin(angle_rad);

    let new_path = '';
    if (path === '') {
      new_path = 'M';
    } else {
      new_path = 'L';
    }
    path += new_path + ' ' + x + ', ' + y + ' ';
  }

  // Add the paths for the error bar sides
  let side_length = 0.05;
  for (let i = 0; i < 2; i++) {
    let angle_degrees = [start_angle, end_angle][i];
    let angle_rad = (angle_degrees * 2 * Math.PI) / 360;

    let xstart = x0 + (r - side_length / 2) * x_over_y * Math.cos(angle_rad);
    let ystart = y0 + (r - side_length / 2) * Math.sin(angle_rad);
    let xend = x0 + (r + side_length / 2) * x_over_y * Math.cos(angle_rad);
    let yend = y0 + (r + side_length / 2) * Math.sin(angle_rad);
    path += 'M ' + xstart + ', ' + ystart + ' L ' + xend + ', ' + yend;
  }

  //path += 'Z';
  return path;
};

const PieChartWithNumber = ({ mean, ci_lower, ci_upper, title }) => {
  const Plotly = usePlotlyContext();

  // values are in decimals, i.e. 0.37 is the value meaning 37%
  let mean_val = mean * 100;
  let start_angle = -ci_lower * 360 + 90;
  let end_angle = -ci_upper * 360 + 90;

  return (
    <Plotly
      className={styles.efficacy_pie_chart}
      data={[
        {
          values: [mean_val, 100 - mean_val],
          labels: ['Value', '1-Value'],
          type: 'pie',
          hole: 0.6,
          domain: { x: [0, 1], y: [0, 1] },
          sort: false,
          direction: 'clockwise',
          textposition: 'none',
          marker: {
            colors: [COLORS.MAIN_SIGNAL, COLORS.MISSING_PIE],
            line: {
              // color: '#bbbbbb',
              color: COLORS.OUTLINES,
              width: 0.5,
            },
          },
        },
      ]}
      layout={{
        shapes: [
          {
            type: 'path',
            xref: 'paper',
            yref: 'paper',
            xanchor: 0,
            yanchor: 0,
            path: arc_path(0.5, 0.5, 0.4, start_angle, end_angle, 100, 0.8666),
            line: {
              // color: '#333333',
              color: COLORS.ERRORBARS,
              width: 1,
            },
          },
        ],
        annotations: [
          {
            x: 0.5,
            y: 0.5,
            xref: 'paper',
            yref: 'paper',
            text: '<b>' + mean_val.toFixed(1) + '%</b>',
            font: {
              family: 'Source Sans Pro',
              size: 40,
              color: '#333333',
            },
            showarrow: false,
          },
        ],
        showlegend: false,
        title: {
          text: '<b>' + titleCase(title) + '</b>',
          font: {
            family: 'Source Sans Pro',
            size: 26,
            color: '#333333',
          },
          xref: 'paper',
          yref: 'paper',
          x: 0.5,
          y: 0,
          yanchor: 'top',
          pad: {
            t: 18,
          },
        },
        height: 300,
        width: 300,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        margin: {
          l: 0,
          r: 0,
          b: 40,
          t: 0,
        },
      }}
      config={{
        staticPlot: true,
      }}
    />
  );
};

const decimal_to_pct = (val_str) => {
  let val = parseFloat(val_str);
  let val_pct = val * 100;
  let rounded = val_pct.toFixed(1);
  return rounded;
};

const InterventionConditionFindingsSummary = ({ data }) => {
  const Link = useLinkContext();

  let all_citations = data['intervention_citations'].split(';;');
  var num_trials_intervention = '';
  if (all_citations.length > 1 && all_citations.length <= 10) {
    num_trials_intervention = converter.toWords(all_citations.length);
    num_trials_intervention += ' ';
  } else if (all_citations.length > 10)
    num_trials_intervention = `${all_citations.length} `;

  var num_trials_total = '';
  if (data.num_trials_total >= 1 && data.num_trials_total <= 10) {
    num_trials_total = converter.toWords(data.num_trials_total);
  } else {
    num_trials_total = `${data.num_trials_total}`;
  }

  let i_mean = data.statistics_intervention.mean;
  let i_ci_lower = data.statistics_intervention.ci_lower;
  let i_ci_upper = data.statistics_intervention.ci_upper;

  let p_mean = data.statistics_placebo.mean;
  let p_ci_lower = data.statistics_placebo.ci_lower;
  let p_ci_upper = data.statistics_placebo.ci_upper;

  let significance_flag = data.is_stat_sig ? 'provides' : 'does not provide';
  let rcts = all_citations.length > 1 ? 'trials' : 'trial';

  let publications = data.num_trials_total > 1 ? 'publications' : 'publication';

  let selecting_text = '';
  // Only say the phrase about selecting if we have identified more than one trial
  if (data.num_trials_total > 1) {
    selecting_text = ` and selecting the ${num_trials_intervention}largest ${rcts} published in the highest-impact peer-reviewed scientific journals (where the relevant clinical endpoint studied in our analysis is published),`;
  }

  let conclusion_text = `After identifying and analyzing ${num_trials_total} ${publications} of clinical trials on ${data.intervention} for ${data.condition}${selecting_text} we find that ${data.intervention} ${significance_flag} a statistically signifcant improvement over placebo.`;
  return (
    <LoadingContainer className={styles.findings_header}>
      <div className={styles.endpoint_title}>
        {titleCase(data['endpoint_name'])}
      </div>
      <div className={styles.sep}></div>
      <div className={styles.time_frame}>{data['endpoint_time_frame']}</div>
      <div className={styles.efficacy_graphic}>
        <PieChartWithNumber
          mean={parseFloat(i_mean)}
          ci_lower={parseFloat(i_ci_lower)}
          ci_upper={parseFloat(i_ci_upper)}
          title={data['intervention']}
        />
        <PieChartWithNumber
          mean={parseFloat(p_mean)}
          ci_lower={parseFloat(p_ci_lower)}
          ci_upper={parseFloat(p_ci_upper)}
          title={'placebo'}
        />
      </div>
      <div className={styles.description}>
        The estimate of the {data['endpoint_name']} when using{' '}
        {data['intervention']} in patients with {data['condition']} is{' '}
        {decimal_to_pct(i_mean)}% (95% CI {decimal_to_pct(i_ci_lower)}% -{' '}
        {decimal_to_pct(i_ci_upper)}%). {conclusion_text}
      </div>

      {data['endpoint_note'] !== undefined && data['endpoint_note'] !== '' ? (
        <div className={styles.description}>{data['endpoint_note']}</div>
      ) : (
        <></>
      )}

      <div className={styles.description_warning}>
        {' '}
        Different treatments for {data.condition} can have different study
        protocols and efficacy numbers may not always be comparable between
        different treatments. The efficacy of the placebo treatment is estimated
        from all analyzed findings for {data.condition}.{' '}
        <Link href='/about#methodology'>Read more about our methodology.</Link>
      </div>
      <LivingReviewTag />
    </LoadingContainer>
  );
};
export default InterventionConditionFindingsSummary;

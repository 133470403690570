import { SxProps, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { formatArticleDate, getDateUpdated } from './utils';

import SocialTray from './SocialTray';

export interface ReviewedByBoxProps {
  auditedBy: string;
  dtPublished?: string;
  articleDate?: string;
  lastModifiedDate?: string;
  styles: Record<string, string>;
  pageType?: string;
}

export function ReviewedByBox({
  auditedBy,
  dtPublished,
  articleDate,
  lastModifiedDate,
  styles,
  pageType,
}: ReviewedByBoxProps) {
  return (
    <div className={styles.author_line}>
      <div className={styles.audited_by}>
        {auditedBy !== '' ? (
          <>
            <b>Medically Reviewed By:</b> {auditedBy}
          </>
        ) : null}
      </div>
      <div className={styles.audited_by}>
        <b>Updated:</b>{' '}
        {formatArticleDate(
          getDateUpdated({
            dt_published: dtPublished,
            article_date: articleDate,
            last_modified_date: lastModifiedDate,
          }),
          pageType
        )}
      </div>
    </div>
  );
}

interface ArticleHeaderProps {
  byline: string;
  title: string | JSX.Element | JSX.Element[];
  subtitle?: string | JSX.Element | JSX.Element[];
  dtPublished?: string;
  articleDate?: string;
  lastModifiedDate?: string;
  keyFindingsSection: JSX.Element | null;
  pageType: string;
  auditedBy: string;
  whatIsThisPage: string;
  leadImageUrl: string | null;
  hideLeadImageInBody?: string;
  conjoined_page: boolean;
  styles: Record<string, string>;
  hideReviewedBy: boolean;
  cursorSxFor: (_location: string) => SxProps;
  shareUrl?: string;
}

export default function ArticleHeader({
  byline,
  subtitle,
  title,
  dtPublished,
  articleDate,
  lastModifiedDate,
  keyFindingsSection,
  auditedBy,
  pageType,
  whatIsThisPage,
  leadImageUrl,
  hideLeadImageInBody,
  conjoined_page,
  styles,
  hideReviewedBy,
  cursorSxFor,
  shareUrl = undefined,
}: ArticleHeaderProps) {
  const titleSx = cursorSxFor('title');

  // If it's an ask_ok page type, we don't want to show the header
  if (pageType.includes('ask_oe') || pageType.includes('ask_finance')) {
    return <></>;
  }

  const hideLeadImage =
    hideLeadImageInBody && ['1', 'true', 'True'].includes(hideLeadImageInBody);

  return (
    <div className={styles.article_header}>
      <Typography
        component='span'
        variant='serif'
        className={styles.title}
        sx={titleSx}
      >
        {title}
      </Typography>
      {subtitle !== '' && typeof subtitle === 'string' ? (
        <span className={styles.subtitle}>{subtitle}</span>
      ) : null}
      {byline && (
        <div className={styles.under_title}>
          <div className={styles.byline}>{parse(byline)}</div>
        </div>
      )}
      <SocialTray title={title} url={shareUrl} />
      {keyFindingsSection}
      {conjoined_page ? (
        <></>
      ) : (
        <>
          {[
            'Science Writer Article',
            'ask_oe',
            'ask_oe_light',
            'ask_oe_light_with_citations',
            'static_content_page',
          ].includes(pageType) &&
          leadImageUrl &&
          !hideLeadImage ? (
            <img
              src={leadImageUrl.toString()}
              className={styles.science_writer_image}
              alt=''
            />
          ) : null}
          {whatIsThisPage ? (
            <div className={styles.what_is_this_page}>
              {parse(whatIsThisPage)}
            </div>
          ) : null}
          {[
            'ask_oe',
            'ask_oe_light',
            'ask_oe_light_with_citations',
            'ask_finance',
          ].includes(pageType) || hideReviewedBy ? null : (
            <ReviewedByBox
              auditedBy={auditedBy}
              dtPublished={dtPublished}
              articleDate={articleDate}
              lastModifiedDate={lastModifiedDate}
              styles={styles}
              pageType={pageType}
            />
          )}
        </>
      )}
    </div>
  );
}

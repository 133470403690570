const SUPPORTED_ICON_NAMES = [
  'star-outline',
  'task-alt',
  'event',
  'feed',
] as const;
type WhyCitedIconName = (typeof SUPPORTED_ICON_NAMES)[number];

interface WhyCitedDisplayInfo {
  color: string;
  iconName: WhyCitedIconName;
}

export function whyCitedTypeToDisplayInfo(
  whyCitedType: string
): WhyCitedDisplayInfo | undefined {
  switch (whyCitedType) {
    case 'impact':
      return {
        color: '#3476b7',
        iconName: 'star-outline',
      };
    case 'relevance':
      return {
        color: '#357638',
        iconName: 'task-alt',
      };
    case 'recency':
      return {
        color: '#8a4097',
        iconName: 'event',
      };
    case 'is_review':
      return {
        color: '#b73476',
        iconName: 'feed',
      };
    default:
      return undefined;
  }
}

// Lightweight enums - the index is the value of the rating
export const RATING_LABELS: { [key: string]: string[] } = {
  impact: ['Leading Journal', 'Leading Journal', 'Leading Journal'],
  recency: ['New Research', 'New Research', 'New Research'],
  relevance: ['Highly Relevant', 'Highly Relevant', 'Highly Relevant'],
};

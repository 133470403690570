/** Truncates text in the UI to n lines, with an ellipsis. Default: n = 3 */
export const getLineClampSx = (n: number = 3) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: n.toString(),
  WebkitBoxOrient: 'vertical',
});

export const flexboxSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

import Table from './Table';

import styles from './Category.module.css';

// TODO: Dedupe with function in Table.js
function dateStringToYMD(dateString) {
  const [year, month, day] = dateString.split('-');
  return [parseInt(year), parseInt(month), parseInt(day)];
}

function compareDateStrings(a, b) {
  const [ay, am, ad] = dateStringToYMD(a);
  const [by, bm, bd] = dateStringToYMD(b);
  return ay - by || am - bm || ad - bd;
}

function comparePublicationDateStrings(a, b) {
  return compareDateStrings(a.publication_date, b.publication_date);
}

const Category = (props) => {
  let trials = props.trials.sort(comparePublicationDateStrings).reverse();
  return (
    <div id={props.name.replace(' ', '-')} className={styles.category}>
      <div className={styles.categoryHeader}>{props.name}</div>
      <div className={styles.categoryTables}>
        {trials.map((trial) => (
          <div key={trial.pmid}>
            <Table {...trial} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;

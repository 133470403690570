import dynamic from 'next/dynamic';
import { createContext, useContext } from 'react';

const Placeholder = ({ width, height, className }: PlaceholderProps) => {
  return <div className={className} style={{ width, height }} />;
};

type PlaceholderProps = {
  width?: number;
  height?: number;
  className?: string;
};

const PlaceholderPropsContext = createContext<PlaceholderProps>({});

const DynamicPlaceholder = dynamic(Promise.resolve(Placeholder), {
  ssr: false,
  loading: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const props = useContext(PlaceholderPropsContext);
    return <Placeholder {...props} />;
  },
});

// eslint-disable-next-line react/display-name
Placeholder.Hydrate = (props: PlaceholderProps) => {
  return (
    <PlaceholderPropsContext.Provider value={props}>
      <DynamicPlaceholder {...props} />
    </PlaceholderPropsContext.Provider>
  );
};

export default Placeholder;

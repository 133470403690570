import Box from '@mui/material/Box';
import OEFinanceLogo from '@/src/components/Finance/OEFinanceLogo';

export default function MobileHeader() {
  return (
    <Box
      sx={{
        /* Do not display if sidebar is present */
        /* TODO: sync this with exactly the styles used for the sidebar */
        display: { xs: 'flex', md: 'none' },
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <OEFinanceLogo />
    </Box>
  );
}

import styles from './TreatmentGuidelines.module.css';

const TreatmentGuidelines = ({ data }) => {
  let condition_sentstart =
    data.condition.charAt(0).toUpperCase() + data.condition.slice(1);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{data.body} Guidelines</div>
      <div className={styles.body}>
        <p>
          {condition_sentstart} is a multifaceted condition, and different
          people have different experiences with it. Beyond clinical efficacy,
          treatment history, disease presentation, co-occuring conditions,
          patient population, and other factors play a role in determining a
          treatment plan. It is important to consult a healthcare professional
          or specialist to learn about individual considerations and to develop
          a specialized treatment plan.
        </p>
        <p>
          More information about individual considerations can be found in the{' '}
          <a href={data.source} target='_'>
            clinical guidelines from the {data.body}
          </a>
          .
        </p>
      </div>
    </div>
  );
};

const renderer = ({ data }) =>
  data.body === '' ? null : <TreatmentGuidelines data={data} />;

export default renderer;

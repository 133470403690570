import { useCallback } from 'react';
import { useTypewriter } from '.';
import { ResetData, ResetType, UseTypewriterTimings } from './types';

export type SplitType = 'word' | 'character';

export const cursorBlinkStyle = (running: boolean) => ({
  '&::after': {
    content: '""',
    width: 9,
    height: 18,
    background: '#999',
    borderRadius: '1px',
    mb: -0.3,
    ml: 0.3,
    display: 'inline-block',
    animation: running ? 'cursor-blink 0.9s step-start 0s infinite' : '',
  },
});

interface UseTextTypewriterOutput {
  textWritten: string[];
  running: boolean;
  done: boolean;
  setTextToWrite: (
    _text: string[],
    _resetType: ResetType,
    _resetData?: ResetData
  ) => void;
  addTextToWrite: (_text: string | string[]) => void;
  startTypewriter: () => void;
  stopTypewriter: () => void;
  typewriterWriteAll: () => void;
}

export default function useTextTypewriter(
  timings: UseTypewriterTimings,
  splitType: SplitType,
  onComplete?: () => void
): UseTextTypewriterOutput {
  const {
    arraysWritten,
    running,
    done,
    setArraysToWrite,
    addArraysToWrite,
    startTypewriter,
    typewriterWriteAll,
    stopTypewriter,
  } = useTypewriter(timings, onComplete);

  const splitChar = splitType === 'word' ? ' ' : '';

  const textWritten = arraysWritten.map((array) => array.join(splitChar));

  const setTextToWrite = useCallback(
    (text: string[], resetType: ResetType, resetData?: ResetData) => {
      const arrays = text.map((string) => string.split(splitChar));
      setArraysToWrite(arrays, resetType, resetData);
    },
    [setArraysToWrite, splitChar]
  );

  const addTextToWrite = useCallback(
    (text: string | string[]) => {
      const arrays =
        typeof text === 'string'
          ? text.split(splitChar)
          : text.map((string) => string.split(splitChar));
      addArraysToWrite(arrays);
    },
    [addArraysToWrite, splitChar]
  );

  return {
    textWritten,
    running,
    done,
    setTextToWrite,
    addTextToWrite,
    startTypewriter,
    stopTypewriter,
    typewriterWriteAll,
  };
}

'use client';

import { Box, Typography } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './shared/accordion-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { useArticleContext } from '../../contexts';
import { useState } from 'react';
import { AnswerEngagedAdditionalInfoEventProps } from '@xyla/analytics';
import { LocalizedText } from '@xyla/util';

interface AccordionViewProps {
  children: JSX.Element;
}

export default function AccordionView({ children }: AccordionViewProps) {
  const { trackArticleEvent } = useArticleContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = () => {
    if (!isExpanded) {
      trackArticleEvent?.('answer_engaged', {
        action: 'Additional Information opened',
      } satisfies AnswerEngagedAdditionalInfoEventProps);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    // offset immediate parent's margin so we can control it ourselves
    <Box sx={{ mb: '-10px' }}>
      <Accordion expanded={isExpanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ContentPasteSearchIcon />
          <Typography>
            <LocalizedText str='Additional Information' />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
}

import { useState } from 'react';
import { CalloutCitedDocument } from './types';
import { DocumentDetails } from './DocumentDetails';
import { Box, Button, Collapse, List, ListItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export interface DocumentDetailsListProps {
  documents: CalloutCitedDocument[];
  previewText?: string | ((documents: CalloutCitedDocument[]) => string);
}

const defaultPreviewText = (documents: CalloutCitedDocument[]) =>
  `${documents.length} Document${documents.length > 1 ? 's' : ''}`;

export const DocumentDetailsList = ({
  documents,
  previewText = defaultPreviewText,
}: DocumentDetailsListProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit sx={{ p: 0, m: 0 }}>
        <List
          aria-label='documents cited'
          sx={{
            p: 0,
            m: 0,
            gap: 1,
            marginTop: 1,
            marginBottom: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {documents.map((document, index) => (
            <ListItem
              key={index}
              sx={{
                p: 0,
                m: 0,
              }}
            >
              <DocumentDetails {...document} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Button
        onClick={handleToggleExpand}
        size='small'
        aria-label={expanded ? 'collapse' : 'expand'}
        sx={{
          padding: 0,
          marginRight: 'auto',
          color: 'grey.700',
          fontWeight: 'regular',
        }}
      >
        {expanded
          ? 'Show less'
          : typeof previewText === 'string'
          ? previewText
          : previewText(documents)}
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </Button>
    </Box>
  );
};

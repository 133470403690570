import { useCallback, useEffect, useRef } from 'react';

/**
 * Applies padding-right to a given element E with `position: fixed` such that
 * E will not jump left/right when an MUI fullscreen component is shown
 * (e.g. Menu, Dialog).
 *
 * @param element - the fixed element to which we should apply styles
 */
export function useFixedElementPadding(element: HTMLElement | null) {
  const observerRef = useRef<MutationObserver | null>(null);

  /*
    When MUI opens a fullscreen element, it adds a padding-right to `body`.
    We read this value when it changes and apply it to the given element.

    We apply the styles directly to the DOM element to avoid UI flashes,
    since it's impractical use React event handlers for every single
    MUI fullscreen component.
  */
  const callback = useCallback(
    (mutations: MutationRecord[]) => {
      if (!element) {
        return;
      }

      const mutation = mutations[0]; // Assume the 1st record contains all we need
      const newValue =
        (mutation.target as HTMLElement).style.paddingRight || '0px';

      // Parse out the oldValue
      let oldValue = '0px';
      if (mutation.oldValue) {
        const parts = mutation.oldValue.split(';');
        const styleRule = parts.find((p) => p.startsWith('padding-right'));
        const foundValue = styleRule?.split(': ')[1];
        if (foundValue) {
          oldValue = foundValue;
        }
      }

      // delta = new - old. We need to add delta to the current style.
      // We do this inside calc() because units may differ.
      const currentStyle = window
        .getComputedStyle(element)
        .getPropertyValue('padding-right');
      element.style.paddingRight = `calc(${currentStyle} + ${newValue} - ${oldValue})`;
    },
    [element]
  );

  // NB(andy): This implementation attaches a new MutationObserver per caller.
  // Assuming limited usage of full-width fixed elements, the performance
  // impact should be negligible.
  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new MutationObserver(callback);
      observerRef.current.observe(document.body, {
        attributes: true,
        attributeFilter: ['style'],
        attributeOldValue: true,
      });
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [callback]);
}

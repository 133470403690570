import {
  Avatar,
  Box,
  ButtonBase,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Link } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import { useArticleContext } from '../contexts';
import { AnswerEngagedExternalLinkClickedEventProps } from '@xyla/analytics';
import { useState } from 'react';
import * as clipboard from 'clipboard-polyfill';

function transformUrl(url: string) {
  let newUrl = url;
  // First, check if the URL ends with a '/' and store this information
  const endsWithSlash = url.endsWith('/');

  // Replace `/` (except final one), `=`, `&`, and `?` with ' > ', while keeping https:// intact
  newUrl = newUrl.replace(/(?<!https:\/)\/(?!\/)|[=&?]/g, ' › ');

  // If the URL originally ended with a '/', remove it
  if (endsWithSlash) {
    newUrl = newUrl.slice(0, -2);
  }

  return newUrl;
}

function MoreMenu({ url }: { url: string }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMoreMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size='small' onClick={handleMoreMenuClick}>
        <MoreVert fontSize='small' />
      </IconButton>
      <Menu
        id='more-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleMoreMenuClose();
            window.open(url, '_blank');
          }}
        >
          Open in new tab
        </MenuItem>
        <MenuItem
          onClick={async () => {
            handleMoreMenuClose();
            await clipboard.writeText(url);
          }}
        >
          Copy URL
        </MenuItem>
      </Menu>
    </>
  );
}

interface SearchLinkProps {
  description: string;
  url: string;
  siteName?: string | null;
  title: string;
  chip?: string;
  maxDescriptionLines?: number;
}
export function SearchLink({
  description,
  url,
  siteName,
  title,
  chip,
  maxDescriptionLines = 3,
}: SearchLinkProps) {
  const { trackArticleEvent } = useArticleContext();

  const displaySiteName = siteName ?? new URL(url).hostname.replace('www.', '');
  const displayUrl = transformUrl(url);

  const isPdf = url.endsWith('.pdf');
  chip = isPdf ? 'pdf' : chip;

  return (
    <>
      <Box
        sx={{
          textAlign: 'left',
          position: 'relative',
          padding: 1,
        }}
      >
        {/* Icon and Title */}
        <ButtonBase
          disableRipple
          onClick={() => {
            trackArticleEvent?.('answer_engaged', {
              action: 'External link clicked',
              url,
            } satisfies AnswerEngagedExternalLinkClickedEventProps);
            window.open(url, '_blank');
          }}
          sx={{
            userSelect: 'auto',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <Box display='flex' alignItems='center' textAlign='left'>
            <Avatar
              sx={{
                bgcolor: 'grey.100',
                height: '30px',
                width: '30px',
                borderRadius: '8px',
                border: '1px solid grey.300',
              }}
            >
              <Box
                component='img'
                src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`}
                alt='icon'
              />
            </Avatar>
            <Box
              display='flex'
              flexDirection='column'
              maxWidth={{ xs: '280px', sm: '600px' }}
            >
              {/* Site Name */}
              <Typography
                variant='body2'
                component='span'
                sx={{ marginLeft: 1 }}
                noWrap
              >
                {displaySiteName}
              </Typography>

              {/* URL (Display link under the title) */}
              <Typography
                variant='caption'
                component='span'
                sx={{ marginLeft: 1 }}
                noWrap
              >
                {displayUrl}
              </Typography>
            </Box>
          </Box>
        </ButtonBase>

        {/* Link (Title of the Result) */}
        <Typography variant='h6' component='div' fontSize={18}>
          <Link
            href={url}
            target='_blank'
            underline='none'
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '&:hover': { textDecoration: 'underline' },
            }}
            onClick={() => {
              trackArticleEvent?.('answer_engaged', {
                action: 'External link clicked',
                url,
              } satisfies AnswerEngagedExternalLinkClickedEventProps);
            }}
          >
            {title}
          </Link>
        </Typography>

        {/* Description */}
        <Typography
          component='div'
          variant='body2'
          color='text.secondary'
          marginTop={0.3}
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: maxDescriptionLines,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {chip && (
            <Chip
              label={chip}
              size='small'
              variant='outlined'
              sx={{
                display: 'inline',
                borderRadius: '5px',
                fontSize: '0.65em',
                height: '15px',
                textTransform: 'uppercase',
                '& .MuiChip-label': {
                  padding: '2px',
                },
              }}
            />
          )}
          {chip && ' '}
          {description}
        </Typography>

        {/* Show more and preview buttons */}
        <Box sx={{ position: 'absolute', top: 3, right: 5 }}>
          {/* TODO(ethan): robustify and enable the preview drawer  */}
          <MoreMenu url={url} />
        </Box>
      </Box>
    </>
  );
}

import Box from '@mui/material/Box';
import { ArticleParagraph, RichCitation } from '../types';
import { ElsevierIcon } from '../ArticleReferences/ElsevierIcon';
import { Link, Typography } from '@mui/material';
import { isRichCitation, parseHtmlAndWhiteSpace } from '../utils';
import DOMPurify from 'isomorphic-dompurify';
import parse from 'html-react-parser';

interface SourceCardProps {
  paragraph: ArticleParagraph;
  paragraphIndex: number;
}

export default function SourceCard({
  paragraph,
  paragraphIndex,
}: SourceCardProps) {
  const citations = paragraph.articlespan_set
    .map((span) => span.citations)
    .flat();
  const citation = citations.length > 0 ? citations[0] : undefined;
  const detail =
    citation && isRichCitation(citation)
      ? (citation as RichCitation).metadata.citation_detail
      : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      {detail?.title && (
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: '500',
          }}
        >
          {detail.href ? (
            <Link
              href={detail.href}
              underline='none'
              target='_blank'
              rel='noopener noreferrer'
            >
              {detail.title}
            </Link>
          ) : (
            detail.title
          )}
        </Typography>
      )}
      {paragraph.articlespan_set.map((span, i) => {
        let output = DOMPurify.sanitize(span.text, { FORBID_TAGS: ['a'] });
        output = parseHtmlAndWhiteSpace(output);
        return (
          <Typography
            component='span'
            variant='serif'
            key={`span_${paragraphIndex}_${i}`}
          >
            <span>{parse(output)}</span>
          </Typography>
        );
      })}
      {detail?.publication_info_string && (
        <Box alignItems='center' display='flex'>
          <ElsevierIcon
            width='16'
            height='16'
            style={{ marginBottom: '3px' }}
          />
          <Typography
            component='span'
            paddingLeft='5px'
            fontSize='16px'
            textTransform={'capitalize'}
          >
            {detail.publication_info_string}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

import styles from './KeyFindingsInfobox.module.css';

const KeyFindingsInfobox = ({ data }) => {
  let all_items = [];
  for (let i = 0; ; i++) {
    let item = data['item' + i.toString()];
    if (item === '') continue;
    if (item === undefined) break;

    all_items.push(item);
  }

  return (
    <div className={styles.container}>
      <ul className={styles.item_list}>
        {all_items.map((item_content, i) => (
          <li key={i} className={styles.item}>
            {item_content}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default KeyFindingsInfobox;

import { useEffect, useState } from 'react';
import { titleCase } from 'title-case';
import wordwrap from 'wordwrapjs';
import { usePlotlyContext } from '../../contexts';

import { Box } from '@mui/material';
import { COLORS } from '@xyla/style';
import LoadingContainer from '../../LoadingContainer';
import styles from './InterventionConditionEffectSizeFigure.module.css';
import OELogo from './OELogo';

function wordWrapNoRunt(input: string, params: any) {
  // Custom wordwrap function that avoids runts (ie single words
  // in the last line).

  // If the input string does not contain a space, return as-is.
  if (input.indexOf(' ') === -1) {
    return wordwrap.wrap(input, params);
  }

  let spaceChar = '_';

  // Replace last whitespace with special character
  let lastSpacePos = input.lastIndexOf(' ');
  var modifiedString =
    input.substring(0, lastSpacePos) +
    spaceChar +
    input.substring(lastSpacePos + 1);

  // Wrap modified string
  modifiedString = wordwrap.wrap(modifiedString, params);

  // Replace magic character with space
  modifiedString = modifiedString.replace(spaceChar, ' ');

  return modifiedString;
}

const countLines = (string: string) => {
  return (string.match(/<br>/g) || []).length + 1;
};

function parseTitleString(string: string) {
  // Parse a string of the form "<population> | <endpoint> (<unit>)" into its parts.
  let stringComponents = string.split('|');
  if (stringComponents.length === 1) {
    return undefined;
  }

  let population = stringComponents[0].trim();
  let endpoint_unit = stringComponents[1].trim().split('(');
  var endpoint = endpoint_unit[0].trim();
  var unit = '';
  if (endpoint_unit.length > 1) {
    unit = endpoint_unit[1].split(')')[0].trim();
  }
  return { population: population, endpoint: endpoint, unit: unit };
}

interface VerticalBarChartData {
  title: string;
  top_caption: string;
  bottom_caption: string;
  ylabel: string;
  xlabel: string;
  values: { name: string; value: string; n: number }[];
  values_are_pct: boolean;
  caption_text: string;
  header: string;
  authors: string;
  publication_year: string;
  journal: string;
}

// This is what used to be "Figure"
function VerticalBarChart({ data }: { data: VerticalBarChartData }) {
  // Data can contain:
  // title (title of the chart), top_caption, bottom_caption, ylabel

  // Defaults
  var yLabel = data['ylabel'] ? data['ylabel'] : '';
  var xLabel = data['xlabel'] ? data['xlabel'] : '';
  var topCaption = data['top_caption'] ? data['top_caption'] : '';
  var bottomCaption = data['bottom_caption'] ? data['bottom_caption'] : '';
  var title = '';

  if (!data['ylabel'] && !data['top_caption'] && !data['bottom_caption']) {
    let parsedData = parseTitleString(data['title']);
    if (parsedData) {
      yLabel = parsedData['endpoint'];
      if (parsedData['unit']) {
        yLabel = yLabel + ' (' + parsedData['unit'] + ')';
      }
      title = '';
      xLabel = 'Population: ' + parsedData['population'];
    } else {
      // Cannot parse. Fall back to old style.
      title = data['title'];
      xLabel = '';
      yLabel = '';
    }
  }
  //let xVals = data['values'].map((valueEntry) => valueEntry.name);
  let xVals = data.values.map((valueEntry) => valueEntry.name);
  let yVals = data.values.map((valueEntry) => parseFloat(valueEntry.value));
  let nVals = yVals.map((_) => -1);
  let errorVals = nVals;
  let valuesArePct = data['values_are_pct'];
  let captionText = data['caption_text'];

  if (captionText) {
    topCaption = captionText;
  }

  var header_string = data['header'];
  if (!header_string) {
    let first_author = data['authors']
      .split(',')[0]
      .split(' ')
      .slice(0, -1)
      .join(' ');
    let publication_year = data['publication_year'];
    let journal = data['journal'];
    header_string =
      first_author + ' et al. - ' + journal + ' (' + publication_year + ')';
  }

  return (
    <LoadingContainer className={styles.container}>
      <div className={styles.header}>{header_string}</div>
      {topCaption === '' || topCaption === undefined ? (
        <Box mb={0}></Box>
      ) : (
        <div className={styles.description}>{topCaption}</div>
      )}
      <VerticalBarChartWithParameters
        title={title}
        xLabel={xLabel}
        yLabel={yLabel}
        xVals={xVals}
        yVals={yVals}
        nVals={nVals}
        errorVals={errorVals}
        valuesArePct={valuesArePct}
      />
      {bottomCaption === '' || bottomCaption === undefined ? (
        <Box mb={0}></Box>
      ) : (
        <div className={styles.description}>{bottomCaption}</div>
      )}
    </LoadingContainer>
  );
}

interface VerticalBarChartWithParametersProps {
  title: string;
  xVals: string[];
  yVals: number[];
  nVals: number[];
  errorVals: number[];
  xLabel: string;
  yLabel: string;
  valuesArePct?: boolean;
}

// This is what used to be "TrialEfficacyFigure"
const VerticalBarChartWithParameters = ({
  title,
  xVals,
  yVals,
  nVals,
  errorVals,
  xLabel = '',
  yLabel = '',
  valuesArePct = false,
}: VerticalBarChartWithParametersProps) => {
  // Keep track of current window dimensions after resizing.
  // Note that when doing SSR, we don't have a window property, so
  // we use the default value of 320 px wide.
  const [currentWindowProperties, setCurrentWindowProperties] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 320,
  });

  useEffect(() => {
    function handleResize() {
      if (window) {
        setCurrentWindowProperties({
          width: window.innerWidth,
        });
      }
    }
    if (window) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize);
      }
    };
  });

  // Decide dynamically if we want to show the mobile version of the figure or not
  let mobile = currentWindowProperties.width <= 600;

  // values are in decimals, i.e. 0.37 is the value meaning 37%
  let nStrings = nVals.map((n) => {
    if (n > 0) {
      return '<br>(n=' + n + ')';
    } else {
      return '';
    }
  });

  let numXLabels = xVals.length;
  let xTotalWidth = mobile ? 40 : 52;
  let numLabelsOffset = 0.1; // Used to reduce the difference between 1 and 2, for example

  let xWidth = (xTotalWidth / (numXLabels + numLabelsOffset)).toFixed();
  let xValsFormatted = xVals.map(
    (val, i) =>
      wordwrap.wrap(val, { width: xWidth, eol: '<br>', break: true }) +
      nStrings[i]
  );
  let extraHeightPerXLabelLine = 20;

  let titleWidth = mobile ? 30 : 40;
  let titleFormatted = wordwrap.wrap(titleCase(title), {
    width: titleWidth,
    eol: '<br>',
    break: true,
  });

  let numTitleLines = countLines(titleFormatted);
  let sizePerTitleLine = 35;
  let topPaddingPerTitleLine = 25;

  let numLabelLines = xValsFormatted.map((string) => countLines(string));
  let maxLabelLines = Math.max(...numLabelLines);
  //let sizePerLabelLine = 20;

  let showError = errorVals.every((val) => val > 0);

  let plotData = {
    x: xValsFormatted,
    y: yVals,
    error_y: {
      type: 'data' as 'data',
      array: errorVals,
      visible: showError,
      //color: '#333333',
      color: COLORS.ERRORBARS,
      thickness: 1.0,
      //width: 4
    },
    type: 'bar' as 'bar',
    width: 0.3,
    marker: {
      //color: '#d09b57',
      color: COLORS.MAIN_SIGNAL,
    },
  };

  // If we have an error bar, add here.
  let upperBounds = showError ? yVals.map((a, i) => a + errorVals[i]) : yVals;
  let ymax = Math.max(...upperBounds);

  let lowerBounds = showError ? yVals.map((a, i) => a - errorVals[i]) : yVals;

  let yrangeMin = 1.2 * Math.min(...lowerBounds);
  var yrangeMax = 1.2 * ymax;

  if (yrangeMin < 0 && yrangeMax < 0) {
    yrangeMax = 0;
  } else if (yrangeMin > 0 && yrangeMax > 0) {
    yrangeMin = 0;
  }

  // Split the y label so that the unit (in brackets) is never split.
  let maxYLabelLength = mobile ? 40 : 30;
  if (yLabel.length > maxYLabelLength) {
    let yLabelSplit = yLabel.split('(');
    var yLabelComponents = wordwrap
      .wrap(yLabelSplit[0].trim(), {
        width: maxYLabelLength,
        eol: '\n',
        break: false,
      })
      .split('\n');
    var yLabelUnit = '';
    if (yLabelSplit.length > 1) {
      yLabelUnit = wordwrap.wrap('(' + yLabelSplit[1].trim(), {
        width: maxYLabelLength,
        eol: '<br>',
        break: false,
      });
    }
    let lastYLabelComponent = yLabelComponents[yLabelComponents.length - 1];
    if (lastYLabelComponent.length + yLabelUnit.length < maxYLabelLength) {
      yLabelComponents[yLabelComponents.length - 1] =
        lastYLabelComponent + ' ' + yLabelUnit;
    } else {
      yLabelComponents.push(yLabelUnit);
    }

    yLabel = yLabelComponents.join('<br>');
  }
  let yLabelLines = yLabel.split('<br>').length;

  let figureWidth = mobile ? currentWindowProperties.width - 70 : 500;

  let xLabelMaxWidth = figureWidth / 9 - 2 * yLabelLines;

  xLabel = wordWrapNoRunt(xLabel, {
    width: xLabelMaxWidth,
    eol: '<br>',
    break: false,
  });
  let xLabelLines = xLabel.split('<br>').length;

  var tickformat = valuesArePct ? '.0%' : undefined;
  if (valuesArePct && ymax < 0.05) {
    tickformat = '.1%';
  } else if (valuesArePct && ymax < 0.005) {
    tickformat = '.2%';
  }

  const Plotly = usePlotlyContext();
  return (
    <>
      <Plotly
        className={styles.trial_efficacy_figure}
        data={[plotData]}
        style={{}}
        layout={{
          showlegend: false,
          title: {
            text: titleFormatted,
            font: {
              size: 18,
            },
            y: 0.9,
            yanchor: 'bottom',
          },
          font: {
            family: 'Source Sans Pro',
            size: mobile ? 13 : 14,
            color: '#333333',
          },
          xaxis: {
            tickangle: 0,
            title: {
              text: xLabel,
              standoff: 20,
            },
            automargin: true,
          },
          yaxis: {
            tickformat: tickformat,
            showline: true,
            showgrid: true,
            gridwidth: 1,
            gridcolor: COLORS.GRID_LINES,
            range: [yrangeMin, yrangeMax],
            title: {
              text: yLabel,
              font: { size: mobile ? 13 : 14 },
              standoff: 15,
            },
            automargin: true,
          },
          height:
            210 +
            sizePerTitleLine * numTitleLines +
            maxLabelLines * extraHeightPerXLabelLine +
            30 * xLabelLines,
          width: figureWidth,
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          margin: {
            t: title ? 20 + topPaddingPerTitleLine * numTitleLines : 20,
            l: 5,
            r: 5,
          },
        }}
        config={{
          staticPlot: true,
        }}
      />
      <OELogo />
    </>
  );
};

export { VerticalBarChart, VerticalBarChartWithParameters, parseTitleString };

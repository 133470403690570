import { YouTubeEmbed } from 'react-social-media-embed';

interface EmbeddedYouTubeProps {
  data: {
    youTubeUrl: string;
  };
}

export default function EmbeddedYouTube({ data }: EmbeddedYouTubeProps) {
  return (
    <YouTubeEmbed key={data.youTubeUrl} url={data.youTubeUrl} width='100%' />
  );
}

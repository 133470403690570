import { Box } from '@mui/material';
import { ReviewedByBox, ReviewedByBoxProps } from './ArticleHeader';

type PreReferencesFooterProps = ReviewedByBoxProps;

export function PreReferencesFooter({
  auditedBy,
  dtPublished,
  articleDate,
  lastModifiedDate,
  styles,
}: PreReferencesFooterProps) {
  return (
    <Box mb={5}>
      <ReviewedByBox
        auditedBy={auditedBy}
        dtPublished={dtPublished}
        articleDate={articleDate}
        lastModifiedDate={lastModifiedDate}
        styles={styles}
      />
    </Box>
  );
}

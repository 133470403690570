import { createContext, useCallback, useContext } from 'react';

// Context
export type TranslateFunction = (
  stringToLocalize: string,
  placeholderValues?: Record<string, any>
) => string;

const defaultTranslateFunction: TranslateFunction = (
  stringToLocalize: string,
  placeholderValues?: Record<string, any>
) => {
  if (placeholderValues) {
    return stringToLocalize.replace(/{{(.*?)}}/g, (match, key) =>
      placeholderValues[key].toString()
    );
  }
  return stringToLocalize;
};

const TranslationContext = createContext<TranslateFunction>(
  defaultTranslateFunction
);

export const useTranslation = () => {
  return useContext(TranslationContext);
};

// Provider
interface TranslationProviderProps {
  languageData: Record<string, string> | undefined;
  children: React.ReactNode;
}

export function TranslationProvider({
  languageData,
  children,
}: TranslationProviderProps) {
  const localizeString = useCallback(
    (stringToLocalize: string, placeholderValues?: Record<string, any>) => {
      const localizedString =
        languageData?.[stringToLocalize] || stringToLocalize;
      // Support placeholders by replace all instances of {{key}} with the value of key in placeholderValues
      if (placeholderValues) {
        return localizedString.replace(/{{(.*?)}}/g, (match, key) =>
          placeholderValues[key].toString()
        );
      }
      return localizedString;
    },
    [languageData]
  );

  return (
    <TranslationContext.Provider value={localizeString}>
      {children}
    </TranslationContext.Provider>
  );
}

interface LocalizedTextProps {
  str: string;
  placeholderValues?: Record<string, any>;
}

export const LocalizedText = ({
  str,
  placeholderValues,
}: LocalizedTextProps) => {
  const localizeString = useTranslation();
  return <>{localizeString(str, placeholderValues)}</>;
};

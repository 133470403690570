import {
  ArticleData,
  ArticleParagraph,
  ArticleSection,
  ArticleSpan,
  Citation,
} from './types';
import {
  Bookmark,
  Document,
  ExternalHyperlink,
  HeadingLevel,
  InternalHyperlink,
  ISectionOptions,
  NumberFormat,
  Packer,
  Paragraph,
  ParagraphChild,
  SectionType,
  TextRun,
} from 'docx';
import FileSaver from 'file-saver';
import { endWithPeriod, isRichCitation } from './utils';

interface CitationIndex {
  citation: Citation;
  index: number;
}

function citationToReferenceId(citation: Citation): string {
  const citationString =
    typeof citation === 'string' ? citation : citation.citation;
  return citationString.toLowerCase().replace(/\s/g, '-');
}

function referenceToDocxParagraph(
  referenceId: string,
  citationIndex: CitationIndex
): Paragraph {
  const { citation } = citationIndex;

  const textRuns = [];
  if (!isRichCitation(citation)) {
    textRuns.push(new TextRun(citation));
  } else {
    const { href, title, authors_string, publication_info_string } =
      citation.metadata.citation_detail;

    textRuns.push(
      new ExternalHyperlink({
        link: href,
        children: [
          new TextRun({
            text: endWithPeriod(title),
            style: 'Hyperlink',
          }),
        ],
      })
    );

    if (authors_string) {
      textRuns.push(new TextRun({ text: ' ' + endWithPeriod(authors_string) }));
    }

    if (publication_info_string) {
      textRuns.push(
        new TextRun({ text: ' ' + endWithPeriod(publication_info_string) })
      );
    }
  }

  const bookmark = new Bookmark({
    children: textRuns,
    id: referenceId,
  });

  return new Paragraph({
    numbering: {
      reference: 'referenceNumbering',
      level: 0,
    },
    children: [bookmark],
  });
}

function referencesToDocxParagraphs(
  references: Map<string, CitationIndex>
): Paragraph[] {
  return Array.from(references.entries()).map(([referenceId, citationIndex]) =>
    referenceToDocxParagraph(referenceId, citationIndex)
  );
}

function referencesToDocxSection(
  references: Map<string, CitationIndex>
): ISectionOptions {
  const titleParagraph = new Paragraph({
    children: [new TextRun('References')],
    heading: HeadingLevel.HEADING_1,
  });
  const referencesParagaphs = referencesToDocxParagraphs(references);
  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },
    children: [titleParagraph, ...referencesParagaphs],
  };
}

function spanTextToDocxTextRuns(text: string): TextRun[] {
  const boldRegex = /<strong>(.*?)<\/strong>/g;
  const parts = text.split(boldRegex);
  const textRuns: TextRun[] = [];

  parts.forEach((part, index) => {
    if (part.length > 0) {
      textRuns.push(
        new TextRun({
          text: part,
          bold: index % 2 !== 0, // Odd indexes are bold (inside <strong> tags)
        })
      );
    }
  });

  return textRuns;
}

function spanToDocxTextRuns(
  span: ArticleSpan,
  mutableReferences: Map<string, CitationIndex>
): ParagraphChild[] {
  // add new citations
  span.citations.forEach((citation) => {
    const referenceId = citationToReferenceId(citation);
    if (!mutableReferences.has(referenceId)) {
      mutableReferences.set(referenceId, {
        index: mutableReferences.size + 1,
        citation,
      });
    }
  });

  // get unique citations and sort by index
  const referenceIds = Array.from(
    new Set(span.citations.map(citationToReferenceId))
  ).sort(
    (a, b) => mutableReferences.get(a)!.index - mutableReferences.get(b)!.index
  );

  return [
    ...spanTextToDocxTextRuns(span.text),
    ...referenceIds.map((referenceId) => {
      const citationIndex = mutableReferences.get(referenceId) as CitationIndex;
      return new InternalHyperlink({
        anchor: referenceId,
        children: [
          new TextRun({
            text: `[${citationIndex.index}]`,
            style: 'Hyperlink',
          }),
        ],
      });
    }),
  ];
}

function paragraphToDocxParagraph(
  paragraph: ArticleParagraph,
  mutableReferences: Map<string, CitationIndex>
): Paragraph {
  return new Paragraph({
    children: [
      ...paragraph.articlespan_set.flatMap((span) =>
        spanToDocxTextRuns(span, mutableReferences)
      ),
    ],
    style: 'Normal',
  });
}

function sectionToDocxSection(
  section: ArticleSection,
  mutableReferences: Map<string, CitationIndex>
): ISectionOptions {
  const paragraphs = [];

  if (section.section_title) {
    const titleParagraph = new Paragraph({
      children: [new TextRun(section.section_title)],
      heading: HeadingLevel.HEADING_1,
    });

    paragraphs.push(titleParagraph);
  }

  const bodyParagraphs = section.articleparagraph_set.map((paragraph) =>
    paragraphToDocxParagraph(paragraph, mutableReferences)
  );
  paragraphs.push(...bodyParagraphs);

  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },
    children: paragraphs,
  };
}

export function articleToDocx(article: ArticleData): Document {
  // Mutable map to store references in the order they appear
  const references = new Map<string, CitationIndex>();

  const sections = article.articlesection_set.map((section) =>
    sectionToDocxSection(section, references)
  );
  if (references.size > 0) {
    const referencesSection = referencesToDocxSection(references);
    sections.push(referencesSection);
  }

  return new Document({
    title: article.title,
    numbering: {
      config: [
        {
          reference: 'referenceNumbering',
          levels: [
            {
              level: 0,
              format: NumberFormat.DECIMAL,
              text: '%1.',
              alignment: 'start',
              style: {
                paragraph: {
                  indent: {
                    left: 720,
                    hanging: 360,
                  },
                  spacing: {
                    before: 0,
                    after: 0,
                  },
                },
              },
            },
          ],
        },
      ],
    },
    styles: {
      paragraphStyles: [
        {
          id: 'Normal',
          name: 'Normal',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 24, // 12 point font
          },
          paragraph: {
            spacing: {
              line: 276,
              before: 0,
              after: 200,
            },
          },
        },
        {
          id: 'Heading1',
          name: 'Heading 1',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 32, // 16 point font
            bold: true,
          },
          paragraph: {
            spacing: {
              before: 480,
              after: 280,
            },
          },
        },
      ],
      characterStyles: [
        {
          id: 'Hyperlink',
          name: 'Hyperlink',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            color: '0000FF',
            underline: {
              type: 'single',
              color: '0000FF',
            },
            size: 24, // 12 point font
            bold: false,
          },
        },
      ],
    },
    sections,
  });
}

export async function downloadDocx(docx: Document, fileName: string) {
  const blob = await Packer.toBlob(docx);
  FileSaver.saveAs(
    blob,
    fileName.endsWith('.docx') ? fileName : `${fileName}.docx`
  );
}

export async function downloadArticleAsDocx(
  article: ArticleData,
  fileName: string
) {
  const docx = articleToDocx(article);
  downloadDocx(docx, fileName);
}

import { UserProvider } from '@auth0/nextjs-auth0/client';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Link from 'next/link';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import createEmotionCache from '../src/createEmotionCache';
import { LinkProvider, PlotlyProvider } from '@xyla/article';
import Plotly from '../src/components/Plotly';
import theme from '../src/theme';
import '../src/global.css';

import { Paper } from '@mui/material';
import MobileHeader from '@/src/components/NavHeader/MobileHeader';
import { ExternalTrackingProvider } from '@xyla/analytics';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const wrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '100vh',
  background: '#000000',
};

const flexNoneStyles = {
  flex: 'none',
};

const contentStyles = {
  flex: 1,
  minHeight: 0,
  padding: '10px 10px 0',
};

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Bison AI</title>
        <meta name='description' content='Bison AI' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <ExternalTrackingProvider>
        <GoogleAnalytics trackPageViews={false} />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UserProvider>
            <PlotlyProvider value={Plotly}>
              <LinkProvider value={Link}>
                <Paper>
                  <Box sx={wrapperStyles}>
                    <Box sx={flexNoneStyles}>
                      <MobileHeader />
                    </Box>
                    <Box sx={contentStyles}>
                      <Component {...pageProps} />
                    </Box>
                  </Box>
                </Paper>
              </LinkProvider>
            </PlotlyProvider>
          </UserProvider>
        </ThemeProvider>
      </ExternalTrackingProvider>
    </CacheProvider>
  );
}

import { IconButton, Snackbar, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export interface ActionButtonProps {
  tooltipText: string;
  icon: any;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  snackbarMessage: string;
}

export function ActionButton({
  tooltipText,
  icon,
  onClick,
  snackbarMessage,
}: ActionButtonProps) {
  const [open, setOpen] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
    onClick(event);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    event?.stopPropagation();
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );

  return (
    <>
      <Tooltip title={tooltipText}>
        <IconButton
          onClick={handleClick}
          sx={{
            color: 'grey.800',
            opacity: 1,
            '&:hover': {
              opacity: 0.6,
            },
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '@media(max-width: 750px)': {
            marginBottom: '65px',
          },
        }}
      />
    </>
  );
}

import { Box, Divider, Typography } from '@mui/material';
import {
  AccordionDetails,
  AccordionSummary,
} from './content/article_components/shared/accordion-components';
import { LocalizedText } from '@xyla/util';
import { useRef } from 'react';

const MAX_CONTENT_HEIGHT = 10000; // have to set this to a large finite number for transitions to work

interface PartialAccordionProps {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  children: React.ReactNode;
  expandIcon: React.ReactNode;
  collapsedHeight?: number;
  leadingIcon?: React.ReactNode;
  title?: string;
}

export function PartialAccordion({
  isExpanded,
  setIsExpanded,
  children,
  expandIcon,
  leadingIcon,
  title,
  collapsedHeight = 400,
}: PartialAccordionProps): JSX.Element | null {
  const contentRef = useRef<HTMLDivElement>(null);

  const contentLargerThanCollapsedHeight = contentRef.current
    ? contentRef.current.scrollHeight > collapsedHeight
    : false;

  const shouldApplyMask =
    (contentRef.current
      ? contentRef.current.scrollHeight > collapsedHeight
      : false) && !isExpanded;

  return (
    <Box
      sx={[
        {
          overflow: 'hidden',
          maxHeight: isExpanded ? MAX_CONTENT_HEIGHT : collapsedHeight,
          transition: 'max-height 0.2s ease-in-out',
          maskImage: !shouldApplyMask
            ? 'none'
            : 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)',
          WebkitMaskImage: !shouldApplyMask
            ? 'none'
            : 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)',
        },
      ]}
    >
      <Divider />
      <AccordionSummary
        expandIcon={
          // todo: use regular accordion if content not larger than collapsed height
          contentLargerThanCollapsedHeight && (
            <Box
              sx={{
                // CSS selector to apply the transition to the icon, not the box
                '& > *': {
                  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
            >
              {expandIcon}
            </Box>
          )
        }
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {leadingIcon}
        {title && (
          <Typography sx={{ ml: '16px', fontSize: '18px' }}>
            <LocalizedText str={title} />
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={[{ pb: 2, color: '#424242' }]}
        onClick={(event) => {
          if (isExpanded) return;
          if ((event.target as HTMLElement).tagName.toLowerCase() !== 'a') {
            setIsExpanded(true);
          }
        }}
      >
        <div ref={contentRef}>{children}</div>
      </AccordionDetails>
    </Box>
  );
}

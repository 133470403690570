import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Callout } from './Callout/Callout';
import { CalloutCitedDocument } from './Callout/types';

export interface GuidelinesSectionProps {
  section_title: string;
  society_name?: string;
  documents?: CalloutCitedDocument[];
  children: React.ReactNode;
}

export const GuidelinesSection = ({
  children,
  section_title,
  society_name,
  documents,
}: GuidelinesSectionProps) => {
  const documentDetailsListPreviewText = (documents: CalloutCitedDocument[]) =>
    `${documents.length} Society Publication${documents.length > 1 ? 's' : ''}`;

  return (
    <Callout
      title={section_title}
      icon={<AssignmentOutlinedIcon />}
      citation={society_name}
      documents={documents}
      documentDetailsListPreviewText={documentDetailsListPreviewText}
    >
      {children}
    </Callout>
  );
};

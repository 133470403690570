export default function mode(
  items: (string | number)[]
): string | number | null {
  if (items.length === 0) return null;
  var modeMap: Record<string | number, number> = {};
  var maxEl = items[0],
    maxCount = 1;
  for (var i = 0; i < items.length; i++) {
    var el = items[i];
    if (modeMap[el] == null) modeMap[el] = 1;
    else modeMap[el]++;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
}

import { titleCase } from 'title-case';
import wordwrap from 'wordwrapjs';

import { COLORS } from '@xyla/style';
import { slugify } from '../../utils';
// import {
//   getAllInterventionInfoForCondition,
//   sortByTitle,
// } from '../../../../data';
import create_citation_span from '../../citations';
import LoadingContainer from '../../LoadingContainer';

import styles from './ConditionMarqueeFigure.module.css';
import LivingReviewTag from './LivingReviewTag';

import { usePlotlyContext } from '../../contexts';

// const collectMissingInterventionData = (
//   conditionName,
//   figureInterventionData
// ) => {
//   let conditionSlug = slugify(conditionName);
//   let fullInterventionData = getAllInterventionInfoForCondition(conditionSlug);
//   let figureInterventionList = figureInterventionData.interventions.map(
//     (x) => x.name
//   );
//   let missingInterventions = fullInterventionData.filter(
//     (x) => !figureInterventionList.includes(x.title)
//   );
//   missingInterventions.sort(sortByTitle);
//   return missingInterventions;
// };

// const MissingDataElement = ({ conditionName, figureInterventionData }) => {
//   let missingInterventions = collectMissingInterventionData(
//     conditionName,
//     figureInterventionData
//   );
//   let missingInterventionsStrings = missingInterventions.map(
//     (x) => `${x.title} (${titleCase(x.subtitle)})`
//   );
//   let missingInterventionsString = missingInterventionsStrings.join(', ');

//   return (
//     <Box
//       width={{ xs: '100%', sm: '95%' }}
//       m='30px auto 0'
//       lineHeight='1.3em'
//       fontSize={16}
//     >
//       Data coming soon for: {missingInterventionsString}
//     </Box>
//   );
// };

const ConditionMarqueePlotly = (props) => {
  const Plotly = usePlotlyContext();
  return <Plotly {...props} />;
};

// const SpecificAdverseEffectsFigure = ({data}) => {
function build_marquee_figure(
  data,
  styles,
  mobile = false,
  disableCitationLinks = false
) {
  let conditionSlug = slugify(data.condition);

  // Define data that will be plotted
  var x_means = [];
  // CI boundaries, given relative to x_means
  var x_upper_errs = [];
  var x_lower_errs = [];
  var bar_colors = [];

  // Holds the y coordinates for each intervention
  var y_values = [];

  let intervention_groups = data.intervention_data.groups;
  let intervention_list = data.intervention_data.interventions;

  /*
    // Add missing interventions
    let missingInterventions = collectMissingInterventionData(data.condition, data.intervention_data);
    missingInterventions = missingInterventions.map(x => ({
        name: x.title,
        route: ['azathioprine', 'colchicine', 'cyclosporine', 'diclofenac', 'ibuprofen', 'ketoprofen', 'naproxen', 'prednisone', 'sulfasalazine'].includes(x.title) ? 'Oral Medications' : 'Injections',
        brand_names: x.subtitle.split(', '),
        mechanism: '',
        citations: [],
        efficacy_stats: {
            mean: 1,
            ci_lower: null,
            ci_upper: null,
            no_data: true,
        }
    }))
    intervention_list = intervention_list.concat(missingInterventions);
    */

  if (intervention_list.length === 0) return '';

  // Holds all annotations
  var annotations = [];

  // Hold the current Y coordinate
  var y_current = 0;

  // Hold all citations.
  // We need to build the whole list of citations here, so that
  // the ordering is given by the ordering in the marquee figure
  var citations_all = [];

  for (let group_index in intervention_groups) {
    let group = intervention_groups[group_index];

    // Get intervention list containing only interventions in current group
    let group_intervention_list = intervention_list.filter((item) => {
      return item.route === group;
    });

    if (group_intervention_list.length === 0) {
      continue;
    }

    // Within the group, sort by efficacy
    group_intervention_list = group_intervention_list.sort((a, b) => {
      if (a.efficacy_stats.no_data) return 1;
      if (b.efficacy_stats.no_data) return -1;
      if (a.efficacy_stats.mean > b.efficacy_stats.mean) {
        return -1;
      } else {
        return 1;
      }
    });

    // Add group annotation if it does not start with '_'
    if (!group.startsWith('_')) {
      let group_annotation = {
        xref: 'x1',
        yref: 'y1',
        x: 0.0,
        y: mobile ? y_current + 0.2 : y_current,
        xanchor: 'left',
        yanchor: 'center',
        text: '<b>' + group + '</b>',
        showarrow: false,
        font: { size: 20 },
      };
      annotations.push(group_annotation);
      y_current += mobile ? 1.4 : 1.0;
    } else {
      // If we don't print the group, y_current is only increased by a little bit
      y_current += 0.5;
    }

    for (let intervention_index in group_intervention_list) {
      // Construct annotation for this data
      let intervention_data = group_intervention_list[intervention_index];
      let intervention_name = titleCase(intervention_data.name);

      // Define formatting of metadata and intervention name
      var name_yanchor = 'center';
      var name_yoffset = 0.0;

      let mechanism = intervention_data.mechanism;
      let brand_names = intervention_data.brand_names;

      // Only add metadata if we actually have it
      if (!mobile && intervention_name !== 'Placebo') {
        var metadata_text = '';
        if (mechanism !== '' || brand_names.length > 0) {
          metadata_text = '(';

          if (mechanism !== '') {
            metadata_text += mechanism;
            if (brand_names.length > 0) metadata_text += '; ';
          }

          if (brand_names.length === 1) {
            metadata_text += 'Brand: ' + brand_names[0];
          } else if (brand_names.length > 1) {
            let brand_name_string = brand_names.slice(0, 3).join(', ');
            metadata_text += 'Brands: ' + brand_name_string;
          }

          metadata_text += ')';
        }

        // Add citations
        let citations_this_intervention = intervention_data.citations;
        // let citation_string = create_citation_span(
        //     citations_this_intervention,
        //     [],
        //     data.citation_data.referencesArr,
        //     0,0,0, // i,j,k
        //     true, // text only
        //     false, // displayDisagree
        //     disableCitationLinks
        // );
        // metadata_text += '<sup>' + citation_string + '</sup>';

        citations_all = citations_all.concat(citations_this_intervention);

        let annotation_metadata = {
          xref: 'x1',
          yref: 'y1',
          x: 1.0,
          y: y_current,
          xanchor: 'right',
          yanchor: 'top',
          text: metadata_text,
          showarrow: false,
          font: { size: 12 },
        };
        annotations.push(annotation_metadata);
        name_yanchor = 'bottom';
        name_yoffset = +0.2;
      }

      var name_xanchor = 'right';
      var name_x = 1.0;

      let interventionSlug = slugify(intervention_name);

      // Make placebo bold and left-aligned
      if (intervention_name === 'Placebo') {
        intervention_name = '<b>' + intervention_name + '</b>';
        name_xanchor = 'right';
        name_x = 1.0;
      } else {
        if (intervention_data.efficacy_stats.no_data) {
          intervention_name = `<span style="color: #aaa">${intervention_name}</span>`;
        } else {
          // Make name a link
          intervention_name = `<a href="/${conditionSlug}/${interventionSlug}" target="_self">${intervention_name}</a>`;
        }
      }

      if (
        mobile &&
        brand_names.length > 0 &&
        !intervention_name.includes('Placebo')
      ) {
        intervention_name += ' (' + brand_names[0] + ')';
      }

      let annotation_name = {
        xref: 'x1',
        yref: 'y1',
        x: name_x,
        y: y_current + name_yoffset,
        xanchor: name_xanchor,
        yanchor: name_yanchor,
        text: intervention_name,
        showarrow: false,
        font: { size: mobile ? 14 : 18 },
      };
      annotations.push(annotation_name);

      // "Coming soon" annotation
      if (intervention_data.efficacy_stats.no_data) {
        let comming_soon_annotation = {
          xref: 'x2',
          yref: 'y2',
          x: 0.02,
          y: y_current + name_yoffset + 0.07,
          xanchor: 'left',
          yanchor: name_yanchor,
          text: 'Coming soon',
          showarrow: false,
          font: {
            size: mobile ? 12 : 14,
            color: '#888',
          },
        };
        annotations.push(comming_soon_annotation);
      }

      // Y coordinate for this intervention
      y_values.push(y_current);

      // Store statistics for this intervention
      let stats = intervention_data.efficacy_stats;
      x_means.push(stats.mean);
      x_upper_errs.push(stats.ci_upper - stats.mean);
      x_lower_errs.push(stats.mean - stats.ci_lower);
      bar_colors.push(stats.no_data ? COLORS.MISSING_PIE : COLORS.MAIN_SIGNAL);

      // Increase y coordinate
      // (increase more if we're at the end of a group)
      if (intervention_index === group_intervention_list.length - 1) {
        y_current += 1.5;
      } else {
        y_current += 1;
      }
    }
  }

  const num_entries = y_current;

  // var xrange_max = 0.0;
  // var xrange_min = 0.0;
  // var bargap = 0.3;
  // var xlabel = "Participants (%)";

  var traces_to_plot = [
    {
      y: y_values,
      x: x_means,
      type: 'bar',
      orientation: 'h',
      marker: {
        color: bar_colors, //COLORS.MAIN_SIGNAL,
      },
      error_x: {
        type: 'data',
        symmetric: false,
        array: x_upper_errs,
        arrayminus: x_lower_errs,
        thickness: 1.0,
        color: COLORS.ERRORBARS,
      },
      xaxis: 'x2',
      yaxis: 'y2',
    },
  ];
  var xrange_min = 0.0;
  var xrange_max = 1.0;

  var yrange_max = num_entries - 0.5;
  var yrange_min = 0.0;

  var xlabel = mobile
    ? data.textual_endpoint_mobile
    : data.textual_endpoint_desktop;
  xlabel = wordwrap.wrap(xlabel, { width: mobile ? 30 : 90, eol: '<br>' });
  xlabel = '<i>' + xlabel + '</i>';
  var bargap = 0.2;

  // How many y units per data unit we want.
  let scale_factor = mobile ? 25 : 40;

  let domain_ylabels = mobile ? [0, 0.55] : [0, 0.35];
  let domain_graph = mobile ? [0.56, 1.0] : [0.37, 1.0];

  // Compute combined citation string
  let { element: citation_string_combined } = create_citation_span(
    citations_all,
    [],
    data.citation_data.referencesArr,
    '0_0_0', // i,j,k
    styles,
    false, // displayDisagree
    disableCitationLinks
  );

  return [
    citation_string_combined,
    <ConditionMarqueePlotly
      key='plot'
      data={traces_to_plot}
      layout={{
        annotations: annotations,
        grid: {
          rows: 1,
          columns: 2,
          column_widths: [1, 3],
          subplots: [['xy', 'x2y2']],
        },
        height: num_entries * scale_factor,
        width: mobile ? 400 : 900,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        barmode: 'group',
        bargap: bargap,
        font: {
          family: 'Source Sans Pro',
          size: mobile ? 13 : 14,
          color: '#333333',
        },
        xaxis: {
          showgrid: false,
          showline: false,
          zeroline: false,
          automargin: true,
          range: [0, 1],
          domain: domain_ylabels,
          tickvals: [],
          ticktext: [],
        },
        yaxis: {
          showgrid: false,
          automargin: true,
          zeroline: false,
          showline: false,
          tickvals: [],
          ticktext: [],
          range: [yrange_max, yrange_min],
        },
        xaxis2: {
          domain: domain_graph,
          showgrid: true,
          showline: true,
          gridcolor: COLORS.GRID_LINES,
          gridwidth: 1,
          linewidth: 1,
          zeroline: true,
          automargin: true,
          range: [xrange_min, xrange_max],
          tickformat: '0%',
          title: {
            text: xlabel,
            font: {
              family: 'Source Sans Pro',
              size: mobile ? 15 : 16,
              color: '#333333',
            },
          },
        },
        yaxis2: {
          showgrid: false,
          automargin: true,
          linewidth: 1,
          showline: false,
          range: [yrange_max, yrange_min],
          tickvals: [],
          ticktext: [],
        },
        margin: {
          l: mobile ? 30 : 10,
          r: mobile ? 50 : 10,
          t: 30,
          b: 30,
        },
      }}
      xaxes={{
        lines: true,
      }}
      config={{
        staticPlot: true,
      }}
    />,
  ];
}

// <SpecificAdverseEffectsFigure data={data} />
const ConditionMarqueeFigure = ({ data, citation, figure, figureMobile }) => {
  let caption_top = `A summary of OpenEvidence's systematic meta-analysis of the efficacy of treatments for ${data.condition} is shown below. Efficacy is measured by the ${data.sheet_endpoint_name}, and data is aggregated from peer-reviewed clinical trials. Non-FDA-approved treatments and treatments without available data are excluded.`;

  let caption_bottom = `Response rate distributions are computed with a random effects model, using data from the largest randomized controlled trials published in the highest-impact peer-reviewed scientific journals. Publications that report the ${data.sheet_endpoint_name} between ${data.sheet_endpoint_time_frame} are included in the analysis. The bar values represent the best estimate of the mean and error-bars represent 95% confidence intervals. Note that the above figure only shows patient response rates and does not include other clinically relevant measures such as side effects.`;

  return (
    <LoadingContainer className={styles.findings_header}>
      <div className={styles.endpoint_title}>
        Clinical Efficacy of Treatments for {titleCase(data.condition)}:
        OpenEvidence Meta-Analysis
      </div>
      <div className={styles.sep}></div>
      <div className={styles.description}>
        {caption_top}
        {citation}
      </div>
      <div className={styles.marquee_graphic}> {figure} </div>
      <div className={styles.marquee_graphic_mobile}> {figureMobile} </div>
      {/*<div className={styles.missing_data}>
                        <MissingDataElement
                            conditionName={data.condition}
                            figureInterventionData={data.intervention_data}
                        />
                    </div>*/}
      <div className={styles.description}> {caption_bottom} </div>
      <LivingReviewTag />
    </LoadingContainer>
  );
};

const renderer = ({
  data,
  styles,
  conjoined_page = false,
  conjoined_page_first_page = false,
}) => {
  // Option from the backend to hide the marquee figure - don't even compute it
  if (data.hide === 'True') {
    return null;
  }

  let disableCitationLinks = conjoined_page || conjoined_page_first_page;
  let [citationStringCombined, figure] = build_marquee_figure(
    data,
    styles,
    false,
    disableCitationLinks
  );
  if (figure === '') {
    return null;
  }
  let [_, figureMobile] = build_marquee_figure(
    data,
    styles,
    true,
    disableCitationLinks
  );

  return (
    <ConditionMarqueeFigure
      data={data}
      citation={citationStringCombined}
      figure={figure}
      figureMobile={figureMobile}
    />
  );
};

export default renderer;

import GetAppIcon from '@mui/icons-material/GetApp';
import { ArticleData } from '../../../types';
import { ActionButton } from './ActionButton';
import { downloadArticleAsDocx } from '../../../docx';

export interface DownloadButtonProps {
  article: ArticleData;
  fileName: string;
}

export const DownloadButton = ({ article, fileName }: DownloadButtonProps) => {
  const onClick = () => {
    downloadArticleAsDocx(article, fileName);
  };

  return (
    <ActionButton
      onClick={onClick}
      snackbarMessage='Downloaded .docx file'
      tooltipText='Download .docx'
      icon={<GetAppIcon />}
    />
  );
};

export default DownloadButton;

import styles from './InterventionConditionEffectSizeFigure.module.css';
import { Box } from '@mui/material';

import {
  VerticalBarChartWithParameters,
  parseTitleString,
} from './VerticalBarChart';
import LoadingContainer from '../../LoadingContainer';

interface InterventionConditionEffectSizeFigureData {
  group_efficacy_vals_cat: string;
  group_names_cat: string;
  group_sizes_cat: string;
  error_vals_cat: string;
  effect_size_is_pct: boolean;
  intervention_citations: string;
  placebo_citations: string;
  endpoint: string;
  authors: string;
  publication_year: string;
  p_value_text: string;
}

const InterventionConditionEffectSizeFigure = ({
  data,
}: {
  data: InterventionConditionEffectSizeFigureData;
}) => {
  /*let all_citations = [...new Set([
        ...data['intervention_citations'].split(';;'),
        ...data['placebo_citations'].split(';;')
    ])];*/
  // If there is no data, we get all the same fields but they are all empty
  if (data['group_efficacy_vals_cat'] === '') {
    return null;
  }

  var yLabel = '';
  var title = data['endpoint'];
  var xLabel = '';

  // Try to parse the endpoint string.
  let parsedData = parseTitleString(data['endpoint']);
  if (parsedData) {
    // We successfully parsed it into its pieces
    yLabel = parsedData['endpoint'];
    if (parsedData['unit']) {
      yLabel = yLabel + ' (' + parsedData['unit'] + ')';
    }
    title = '';
    xLabel = 'Population: ' + parsedData['population'];
  }

  let firstAuthor = data['authors'].split(' ')[0];
  let publicationYear = data['publication_year'];
  let xVals = data['group_names_cat'].split(';;');
  let yVals = data['group_efficacy_vals_cat']
    .toString()
    .split(';;')
    .map((val) => parseFloat(val));
  let nVals = data['group_sizes_cat']
    .toString()
    .split(';;')
    .map((val) => parseInt(val));
  let errorVals = data['error_vals_cat']
    .toString()
    .split(';;')
    .map((val) => parseFloat(val));

  let valuesArePct = data['effect_size_is_pct'];

  return (
    <LoadingContainer className={styles.container}>
      <div className={styles.header}>
        {firstAuthor} et al. - {publicationYear}
      </div>
      {data['p_value_text'] === '' ? (
        <Box mb={0}></Box>
      ) : (
        <div className={styles.description}>{data['p_value_text']}</div>
      )}
      <VerticalBarChartWithParameters
        title={title}
        yLabel={yLabel}
        xLabel={xLabel}
        xVals={xVals}
        yVals={yVals}
        nVals={nVals}
        errorVals={errorVals}
        valuesArePct={valuesArePct}
      />
    </LoadingContainer>
  );
};

const renderer = ({
  data,
}: {
  data: InterventionConditionEffectSizeFigureData;
}) => {
  // If there is no data, we get all the same fields but they are all empty
  if (data['group_efficacy_vals_cat'] === '') {
    return null;
  }
  return <InterventionConditionEffectSizeFigure data={data} />;
};

export { InterventionConditionEffectSizeFigure };
export default renderer;

import { Fragment } from 'react';
import { Link } from 'react-scroll';
import clsx from 'clsx';

import type { Citation, LegacyCitation } from './types';
import { isRichCitation } from './utils';

// When we scroll to an element, offset global header height
export const CITATION_SCROLL_OFFSET = -100;

// Suffix used to distinguish citation/references from other react-scroll links
export const CITATION_LINK_SUFFIX = '_refLink';

class CtrlLink extends Link {
  handleClick = (event: any) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(window.location.pathname + '#' + this.props.to, '_blank');
    } else {
      (this as any).scrollTo(this.props.to, this.props);
    }
  };
}

/** Returns the full citation content as a single string */
export function getPlainCitationString(reference: Citation): string {
  if (typeof reference === 'string') {
    return reference;
  } else {
    return reference.citation;
  }
}

/** Array.prototype.findIndex for Citation arrays */
export function findCitationIndex(arr: Citation[], citation: Citation): number {
  const searchString = getPlainCitationString(citation);
  return arr.findIndex((el) => getPlainCitationString(el) === searchString);
}

function add_to_references_get_number_to_disagree(
  citations: Citation[],
  citation_disagrees: (0 | 1)[],
  referencesArr: Citation[]
): Record<number, number> {
  // Create the citation elements
  const citation_number_to_disagree: Record<number, number> = {};
  for (let i = 0; i < citations.length; i++) {
    const citation = citations[i];
    const disagree = citation_disagrees[i];

    let citationIndex = findCitationIndex(referencesArr, citation);
    if (citationIndex === -1) {
      // NB (andy): Mutation happens here

      // Compute snippet text with highlights
      const normalizedCitation: Citation =
        isRichCitation(citation) && citation.metadata.snippet_text
          ? {
              ...citation,
              all_snippet_display_data: [
                {
                  text: citation.metadata.snippet_text,
                  critical_sentences: citation.metadata.critical_sentences,
                  image_data: citation.metadata.citation_detail.image_data,
                },
              ],
            }
          : citation;

      referencesArr.push(normalizedCitation);
      citationIndex = referencesArr.length - 1;
    } else {
      // We've already found this citation.
      // If it is a rich citation that has different snippet text, add it to the list of snippet text
      const existingCitation = referencesArr[citationIndex];
      let newCitation: Citation = existingCitation;
      if (isRichCitation(citation) && isRichCitation(existingCitation)) {
        // Check if the snippet text is already in the list
        const newSnippetText = citation.metadata.snippet_text;
        if (newSnippetText) {
          const newCriticalSentences = citation.metadata.critical_sentences;
          if (
            !existingCitation.all_snippet_display_data?.some(
              (snippet) => snippet.text === newSnippetText
            )
          ) {
            // The snippet text is not already in the list or the list does not exist
            const snippetDisplayData = {
              text: newSnippetText,
              critical_sentences: newCriticalSentences,
              image_data: citation.metadata.citation_detail.image_data,
            };

            // Make the new list
            const newSnippetDisplayDataArray =
              existingCitation.all_snippet_display_data
                ? [
                    ...existingCitation.all_snippet_display_data,
                    snippetDisplayData,
                  ]
                : [snippetDisplayData];

            // Make the new citation
            newCitation = {
              ...existingCitation,
              all_snippet_display_data: newSnippetDisplayDataArray,
            };
          }
        }

        // Add the new citation to the list
        referencesArr[citationIndex] = newCitation;
      }
    }
    const number = citationIndex + 1;
    citation_number_to_disagree[number] = disagree;
  }
  return citation_number_to_disagree;
}

function ct_to_citation_string(ct: LegacyCitation): string {
  let pubmed_url = 'https://pubmed.ncbi.nlm.nih.gov/' + ct.pmid;
  let title_string = `<a target="_blank" href="${pubmed_url}">${ct.title}</a>`;
  let citation_string = ct.citation.replace(ct.title, title_string);
  return citation_string;
}

function create_citation_span(
  citations: Citation[],
  citation_disagrees: (0 | 1)[],
  referencesArr: Citation[],
  prefix: string,
  styles: Record<string, string>,

  // Set to true to display disagreeing citations
  displayDisagree = false,

  // Set to true to disable links to references
  disableLinks = false,

  questionIndex?: number,
  askWrapperContainerId?: string
): { text: string[]; element: JSX.Element } {
  let citation_number_to_disagree = add_to_references_get_number_to_disagree(
    citations,
    citation_disagrees,
    referencesArr // gets mutated!
  );
  let citation_numbers = Object.keys(citation_number_to_disagree)
    .map((number) => +number)
    .sort((a, b) => a - b);

  let prev_start: number | null = null;
  let last_number: number | null = null;
  const citation_elements: JSX.Element[] = [];
  const citation_text_chunks: string[] = [];

  function end_last_bracket() {
    if (last_number === null || prev_start === null) return;
    let ref_str = null;
    if (last_number - prev_start > 0)
      ref_str = `[${prev_start}-${last_number}]`;
    else ref_str = `[${prev_start}]`;

    const key = `${prefix}_${prev_start}_citelink`;

    let disagree =
      displayDisagree && citation_number_to_disagree[prev_start] === 1;

    citation_text_chunks.push(ref_str);

    let elementLink = disableLinks ? (
      <Fragment key={key}>{ref_str}</Fragment>
    ) : (
      <CtrlLink
        className={disagree ? styles.disagree : ''}
        key={key}
        to={`${questionIndex}_${prev_start}${CITATION_LINK_SUFFIX}`}
        offset={CITATION_SCROLL_OFFSET}
        containerId={askWrapperContainerId}
      >
        {ref_str}
      </CtrlLink>
    );
    citation_elements.push(elementLink);
  }

  for (let number of citation_numbers) {
    // Figure out what we need to do
    let end_last = false;
    let start_new = false;
    if (last_number == null) {
      start_new = true;
    } else if (
      number - last_number > 1 ||
      (displayDisagree &&
        citation_number_to_disagree[last_number] !==
          citation_number_to_disagree[number])
    ) {
      start_new = true;
      end_last = true;
    }

    // Handle ending a bracket
    if (end_last) {
      end_last_bracket();
    }
    // Handle starting a new bracket
    if (start_new) {
      prev_start = number;
    }
    last_number = number;
  }
  if (prev_start != null) end_last_bracket();

  return {
    text: citation_text_chunks,
    element: (
      <span
        key={`${prefix}_${prev_start}_citelink`}
        className={clsx(
          disableLinks ? styles.citations_disabled : styles.citations,
          'brandable--citation'
        )}
      >
        {citation_elements}
      </span>
    ),
  };
}

export {
  add_to_references_get_number_to_disagree,
  ct_to_citation_string,
  create_citation_span,
  CtrlLink,
};

export default create_citation_span;

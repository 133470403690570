import { Box, Link, SxProps, Theme, Typography } from '@mui/material';
import { CalloutCitedDocument } from './types';

export interface DocumentDetailsProps extends CalloutCitedDocument {
  sx?: SxProps<Theme>;
}

export const DocumentDetails = ({
  title,
  published_date_text,
  href,
  sx = {},
}: DocumentDetailsProps) => (
  <Box
    sx={[
      {
        dispay: 'flex',
        flexDirection: 'column',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Typography
      sx={{
        fontSize: 'inherit',
      }}
    >
      {href ? (
        <Link
          href={href}
          target='_blank'
          sx={{
            color: 'primary.main',
            fontWeight: 450,
          }}
        >
          {title}
        </Link>
      ) : (
        title
      )}
    </Typography>
    {published_date_text && (
      <Typography
        sx={{
          fontSize: 'inherit',
          fontStyle: 'italic',
          color: 'grey.700',
        }}
      >
        {published_date_text}
      </Typography>
    )}
  </Box>
);

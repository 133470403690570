import styles from './LivingReviewTag.module.css';

const LivingReviewTag = () => (
  <div className={styles.living_review_tag}>
    <div className={styles.living_review_container}>
      <img
        className={styles.living_review_indicator}
        src='https://storage.googleapis.com/xyla_static_data/.images/living_review_indicator_stacked.png'
        alt='Logo that says living systematic reviews of clinical evidence'
      />
    </div>
  </div>
);

export default LivingReviewTag;

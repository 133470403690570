import { Link as ScrollLink } from 'react-scroll';

//const page_types_with_no_toc = ['Science Writer Article'];
const page_types_with_no_toc: string[] = [];
const maxItems = 8; // If more than this, disable the ToC entirely

interface TableOfContentsProps {
  pageType: string;
  sectionTitles: [string, string, string][];
  references: unknown[];
  conjoined_page: boolean;
  conjoined_page_first_page: boolean;
  styles: Record<string, string>;
}

export default function TableOfContents({
  pageType,
  sectionTitles,
  references,
  conjoined_page,
  conjoined_page_first_page,
  styles,
}: TableOfContentsProps) {
  if (
    page_types_with_no_toc.includes(pageType) ||
    conjoined_page ||
    conjoined_page_first_page ||
    sectionTitles.length > maxItems
  )
    return null;
  return (
    <div className={styles.table_of_contents_wrapper}>
      <div className={styles.table_of_contents_fixed_width_wrapper}>
        <ul className={styles.table_of_contents}>
          <div className={styles.table_of_contents_title}>Contents</div>
          {sectionTitles.map((type_slug_string, i) => (
            <ScrollLink to={type_slug_string[1]} offset={-100} key={i}>
              <li className={styles['toc_link_' + type_slug_string[0]]}>
                {type_slug_string[2]}
              </li>
            </ScrollLink>
          ))}
          {references.length > 0 ? (
            <ScrollLink to='References' offset={-100}>
              <li className={styles.toc_link_section}>References</li>
            </ScrollLink>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
}

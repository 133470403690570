import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkIcon from '@mui/icons-material/Link';
import Share from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';

import { SxProps } from '@mui/material';
import {
  ArticleShareButtonClickedEventProps,
  useExternalTracking,
} from '@xyla/analytics';
import * as clipboard from 'clipboard-polyfill';

const buttonStyle = {
  color: 'inherit',
  p: { xs: 0.5, sm: 1 },
};

interface ShareButtonProps {
  icon: JSX.Element;
  href: string;
  title?: string;
  onClick?: () => void;
}

/** Style and default props for icon button re-used in the main component */
const ShareButton = ({ icon, href, title, onClick }: ShareButtonProps) => (
  <IconButton
    href={href}
    target='_blank'
    rel='noreferrer'
    title={title}
    sx={buttonStyle}
    onClick={onClick}
  >
    {icon}
  </IconButton>
);

/** Button to copy link to clipboard */
export const CopyLinkButton = ({
  url,
  bottomAligned,
  sx,
  onClick,
}: {
  url: string;
  bottomAligned: boolean;
  sx?: SxProps;
  onClick?: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    clipboard.writeText(url);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        title='Copy link to clipboard'
        onClick={() => {
          handleClick();
          if (onClick) {
            onClick();
          }
        }}
        sx={{ ...buttonStyle, ...sx }}
      >
        <LinkIcon />
      </IconButton>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message='Link copied to clipboard'
        anchorOrigin={{
          vertical: bottomAligned ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        sx={{ bottom: bottomAligned ? { xs: '70px', sm: '22px' } : 'auto' }}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleClose}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      />
    </>
  );
};

interface SocialTrayProps {
  title: string | JSX.Element | JSX.Element[];
  url?: string;
  bottomAligned?: boolean;
  articleType?: string;
  sx?: SxProps;
}

/** Main component, displays share buttons in a row */
export default function SocialTray({
  title,
  url,
  bottomAligned = false,
  articleType,
  sx,
}: SocialTrayProps) {
  const [isMounted, setIsMounted] = useState(false);
  const { trackMixpanelEvent } = useExternalTracking();

  useEffect(() => setIsMounted(true), []);

  if (url === undefined) {
    return null;
  }

  const hasNativeShare = isMounted && !!navigator.share;

  let shareTextPrefix;
  let mailtoBodyPrefix;
  let mailtoSubjectPrefix;

  // change the text for bison
  if (articleType === 'Ask Finance') {
    shareTextPrefix = 'Check out this question on Bison AI';
    mailtoSubjectPrefix = 'Bison AI';
    mailtoBodyPrefix = 'Check out this article on Bison AI';
  } else {
    // keep original text for ask_oe
    shareTextPrefix = 'Check out this article on OpenEvidence';
    mailtoSubjectPrefix = 'OpenEvidence';
    mailtoBodyPrefix = 'Check out this article on OpenEvidence';
  }

  const shareText = `${shareTextPrefix}: ${title.toString()}`;
  const mailtoSubject = `${mailtoSubjectPrefix} - ${title.toString()}`;
  const mailtoBody = `${mailtoBodyPrefix}: ${title.toString()}\n${url}`;

  // TODO(andy): Add UTM params to the share URL's so we can track shares

  const trackShareButtonClickedEvent = (
    label: ArticleShareButtonClickedEventProps['label']
  ) => {
    trackMixpanelEvent?.('article_share_button_clicked', {
      label: label,
    } satisfies ArticleShareButtonClickedEventProps);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap',
        mx: { xs: -0.5, sm: -1 }, // compensates for default MUI button padding
        ...sx,
      }}
    >
      {hasNativeShare && (
        <IconButton
          onClick={() => {
            window.navigator.share({
              title: shareText,
              url: url,
            });
            trackShareButtonClickedEvent('Native Share');
          }}
          sx={buttonStyle}
        >
          <Share sx={{ fontSize: '20px' }} />
        </IconButton>
      )}
      <ShareButton
        icon={<FacebookIcon />}
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`}
        title='Share on Facebook'
        onClick={() => trackShareButtonClickedEvent('Facebook')}
      />
      <ShareButton
        icon={<TwitterIcon />}
        href={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(
          shareText
        )}`}
        title='Share on Twitter'
        onClick={() => trackShareButtonClickedEvent('Twitter')}
      />
      <ShareButton
        icon={<EmailIcon />}
        href={`mailto:?subject=${encodeURIComponent(
          mailtoSubject
        )}&body=${encodeURIComponent(mailtoBody)}`}
        title='Share via email'
        onClick={() => trackShareButtonClickedEvent('Email')}
      />
      <CopyLinkButton
        url={url}
        bottomAligned={bottomAligned}
        onClick={() => trackShareButtonClickedEvent('Copy Link')}
      />
    </Box>
  );
}

import dynamic from 'next/dynamic';
import { useLoadingContainer } from '@xyla/article';
import Placeholder from './Placeholder';
import { memo } from 'react';

type PlotlyProps = {
  className?: string;
  layout: {
    width: number;
    height: number;
  };
} & any; // TODO(andy): Plotly cleanup - fix typing

// Dynamically import react-plotly because it depends on Browser API's, so it can't be run on the server.

// TODO(andy): Plotly cleanup - Loading state isn't needed here because it is
// obscured by `renderPlaceholder` below. In the main component, we always return
// <Placeholder.Hydrate> until the lib is finished loading.
// Clean up all the needless loading context stuff, then add a proper loading state here.
// Remember height/width should stay the same to prevent CLS (see Placeholder.tsx)
const Plot = dynamic(() => import('./PlotlyClientSide'), {
  ssr: false,
});

const Plotly = memo(function Plotly(props: PlotlyProps) {
  const {
    className,
    layout: { width, height },
  } = props;
  const { markAsLoaded, renderPlaceholder } = useLoadingContainer();
  if (renderPlaceholder) {
    return (
      <Placeholder.Hydrate
        width={width}
        height={height}
        className={className}
      />
    );
  }
  return <Plot {...props} onInitialized={markAsLoaded} />;
});

export default Plotly;

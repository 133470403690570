export type iterable = any[];

export interface TypewriterPositionUpdate {
  currentArray?: number;
  currentIndexInEachArray?: number[];
}

export interface TypewriterState {
  arraysToWrite: iterable[];
  currentArray: number;
  currentIndexInEachArray: number[]; // Keeps track of how many objects have been processed by the typewriter in each array to write
}

export enum ResetType {
  None,
  All,
  Specific,
}

export interface TableWord {
  row_index: number;
  column_name: string;
  word: string;
}

export interface TableComponent {
  table_data: Record<string, string>[]; // Each row corresponds to a single dictionary {column1_name: cell value, column2_name: cell value, ...}
}

export interface ResetData {
  currentArray: number;
  currentIndexInCurrentArray: number;
}

export type TypewriterAction =
  | {
      type: 'setArraysToWrite';
      arrays: iterable[];
      resetType: ResetType;
      resetData?: ResetData;
    }
  | { type: 'writeItem' }
  | { type: 'writeAll' };

export interface UseTypewriterTimings {
  delayMsStart: number;
  delayMsEnd: number;
  longWaitFreq: number;
  longWaitDelayMsStart: number;
  longWaitDelayMsEnd: number;
  newlineDelayMsStart: number;
  newlineDelayMsEnd: number;
  initialDelayMs?: number;
}

import { titleCase } from 'title-case';
import wordwrap from 'wordwrapjs';

import { COLORS } from '@xyla/style';
import { usePlotlyContext, useLinkContext } from '../../contexts';
import LoadingContainer from '../../LoadingContainer';

import LivingReviewTag from './LivingReviewTag';
import styles from './InterventionConditionAdverseEffectsSummary.module.css';

function conjoin(aes) {
  var ae_strings = aes.map((x) => {
    return `${x[0]} (+${(x[1] * 100).toFixed(1)}%)`;
  });
  var output_string = '';
  for (let i in ae_strings) {
    let ae_str = ae_strings[i];

    if (i === 0) {
      output_string = ae_str;
    } else if (i === aes.length - 1) {
      if (aes.length === 2) {
        output_string = output_string + ' and ' + ae_str;
      } else {
        output_string = output_string + ', and ' + ae_str;
      }
    } else {
      output_string = output_string + ', ' + ae_str;
    }
  }
  return output_string;
}

function build_top_text(intervention, worst_3_aes) {
  var top_text = '';
  if (worst_3_aes.length === 0) {
    top_text =
      titleCase(intervention) +
      ' is FDA-approved and considered safe, and the OpenEvidence meta-analysis does not identify that ' +
      intervention +
      ' leads to a meaningfully increased (>+1.0 %) incidence of serious adverse events.';
  } else {
    let side_effect_string = conjoin(worst_3_aes);
    top_text =
      titleCase(intervention) +
      ' is FDA-approved. The OpenEvidence meta-analysis identifies that ' +
      intervention +
      ' led to a substantially higher incidence of serious ' +
      side_effect_string.toLowerCase() +
      ' compared to placebo.';
  }

  return top_text;
}

// const SpecificAdverseEffectsFigure = ({data}) => {
function SpecificAdverseEffectsFigure(data, mobile = false) {
  const Plotly = usePlotlyContext();

  var ynames = [];
  var xvals_intervention = [];
  var xvals_intervention_err_upper = [];
  var xvals_intervention_err_lower = [];
  var xvals_placebo = [];
  var xvals_placebo_err_upper = [];
  var xvals_placebo_err_lower = [];

  var xvals_delta = [];
  // This is set to zero for all proper values, but to slightly negative
  // for small values.
  var xvals_base = [];

  var max_x_ci_value = 0.0;
  var max_x_delta_value = 0.0;
  var min_x_delta_value = 0.0;

  let ae_statistics_list_original = data.adverse_events.ae_statistics_list;
  var ae_statistics_list = [...ae_statistics_list_original];
  // Sort inverted
  ae_statistics_list.sort((a, b) => {
    let adv_a = a.name_desktop.toLowerCase(),
      adv_b = b.name_desktop.toLowerCase();
    if (adv_a > adv_b) return -1;
    else return 1;
  });

  if (ae_statistics_list.length === 0) return '';

  for (let i in ae_statistics_list) {
    let ae_statistics = ae_statistics_list[i];

    var inter_mean = ae_statistics.statistics_intervention.mean;
    var placebo_mean = ae_statistics.statistics_placebo.mean;

    var delta = inter_mean - placebo_mean;
    // Use new comparison statistics if they exist.
    if ('statistics_comparison' in ae_statistics) {
      delta = ae_statistics.statistics_comparison.mean;
    }

    // If delta is too small, continue
    if (Math.abs(delta) < 0.001) continue;

    ynames.push(
      titleCase(mobile ? ae_statistics.name_mobile : ae_statistics.name_desktop)
    );

    xvals_intervention.push(inter_mean);
    xvals_placebo.push(placebo_mean);

    if (Math.abs(delta) < 0.0008) {
      // We draw a short error bar, symmetric around 0.
      xvals_delta.push(0.0008);
      xvals_base.push(-0.0004);
    } else {
      xvals_delta.push(delta);
      xvals_base.push(0.0);
    }

    xvals_intervention_err_upper.push(
      Math.min(1.0, ae_statistics.statistics_intervention.ci_upper) - inter_mean
    );
    xvals_intervention_err_lower.push(
      inter_mean - Math.max(0.0, ae_statistics.statistics_intervention.ci_lower)
    );

    xvals_placebo_err_upper.push(
      Math.min(1.0, ae_statistics.statistics_placebo.ci_upper) - placebo_mean
    );
    xvals_placebo_err_lower.push(
      placebo_mean - Math.max(0.0, ae_statistics.statistics_placebo.ci_lower)
    );

    // keep track of maximum x value, so we can set the xrange accordingly
    max_x_ci_value = Math.max(
      max_x_ci_value,
      Math.max(
        ae_statistics.statistics_placebo.ci_upper,
        ae_statistics.statistics_intervention.ci_upper
      )
    );

    // Store min and max values
    max_x_delta_value = Math.max(max_x_delta_value, inter_mean - placebo_mean);
    min_x_delta_value = Math.min(min_x_delta_value, inter_mean - placebo_mean);
  }

  // Wrap names
  let ynames_wrapped = ynames.map((val) =>
    wordwrap.wrap(val, { width: mobile ? 30 : 40, eol: '<br>' })
  );

  const name_intervention = titleCase(data.intervention);
  const num_entries = ynames.length;

  var data_to_plot = [];

  data_to_plot = [
    {
      y: ynames_wrapped,
      x: xvals_delta,
      base: xvals_base,
      type: 'bar',
      orientation: 'h',
      marker: {
        color: COLORS.MAIN_SIGNAL,
      },
      name: name_intervention,
    },
  ];
  let xrange_max = 1.05 * max_x_delta_value;
  let xrange_min = Math.min(0, 1.05 * min_x_delta_value);
  xrange_max = mobile ? Math.max(xrange_max, 0.03) : Math.max(xrange_max, 0.05);
  xrange_min = mobile
    ? Math.min(xrange_min, -0.001)
    : Math.min(xrange_min, -0.001);

  let bargap = 0.3;
  let n_entries = ynames_wrapped.length;

  let xlabel = 'Side effect incidence (% participants) compared to placebo';
  xlabel = wordwrap.wrap(xlabel, { width: mobile ? 30 : 80, eol: '<br>' });

  let min_height = mobile ? 180 : 140;

  return (
    <Plotly
      data={data_to_plot}
      layout={{
        height: Math.max(min_height, num_entries * 35),
        width: mobile ? 400 : 800,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        barmode: 'group',
        bargap: bargap,
        font: {
          family: 'Source Sans Pro',
          size: mobile ? 13 : 14,
          color: '#333333',
        },
        xaxis: {
          showgrid: true,
          showline: true,
          //gridcolor: '#CCCCCC',
          gridcolor: COLORS.GRID_LINES,
          gridwidth: 1,
          //linecolor: '#CCCCCC',
          linewidth: 1,
          zeroline: true,
          automargin: true,
          range: [xrange_min, xrange_max],
          tickformat: '0%',
          title: {
            text: xlabel,
            font: {
              family: 'Source Sans Pro',
              size: mobile ? 13 : 14,
              color: '#333333',
            },
          },
        },
        yaxis: {
          showgrid: false,
          automargin: true,
          linewidth: 1,
          showline: false,
          tickvals: Array(n_entries).keys(),
          ticktext: ynames_wrapped,
        },
        legend: {
          x: 1,
          y: 1,
          xanchor: 'right',
          traceorder: 'reversed',
        },
        margin: {
          l: mobile ? 40 : 10,
          r: mobile ? 40 : 10,
          t: 30,
          b: 30,
        },
      }}
      xaxes={{
        lines: true,
      }}
      config={{
        staticPlot: true,
      }}
    />
  );
}

// <SpecificAdverseEffectsFigure data={data} />
const InterventionConditionAdverseEffectsSummary = ({ data }) => {
  const Link = useLinkContext();

  let fig = SpecificAdverseEffectsFigure(data, false);
  if (fig === '') {
    // Build fallback figure
    return (
      <div className={styles.container}>
        <div className={styles.findings_header_notfound}>
          <div className={styles.description}>
            OpenEvidence did not find any clinical trials meeting our{' '}
            <Link href='/about#methodology'>inclusion criteria</Link> that
            report serious adverse events of {data.intervention.toLowerCase()}{' '}
            in patients with {data.condition.toLowerCase()}.
          </div>
        </div>
      </div>
    );
  }
  let fig_mobile = SpecificAdverseEffectsFigure(data, true);

  // Build top text
  let top_text = build_top_text(
    data.intervention,
    data.adverse_events.worst_3_aes
  );

  var caption_relative_precite = '';
  if (data.adverse_events.n_trials === 1) {
    caption_relative_precite = `Incidence of serious side effects associated with ${data.intervention} in patients with ${data.condition}, represented as increase in incidences for each side effect compared to placebo.`;
  } else {
    caption_relative_precite = `Incidence of serious side effects associated with ${data.intervention} in patients with ${data.condition}, represented as increase in incidences for each side effect compared to placebo, based on a meta-analysis of ${data.adverse_events.n_trials} trials.`;
  }

  return (
    <LoadingContainer className={styles.container}>
      <div className={styles.findings_header}>
        <div className={styles.endpoint_title}>
          Serious Side Effects of {titleCase(data.intervention)} in Patients
          with {titleCase(data.condition)}
        </div>
        <div className={styles.sep}></div>
        <div className={styles.description}>{top_text}</div>

        <div className={styles.ae_graphic}> {fig} </div>
        <div className={styles.ae_graphic_mobile}> {fig_mobile} </div>
        <div className={styles.description}>
          {caption_relative_precite}
          {data.citation_data.full_citation_span}
          <div className={styles.description_warning}>
            Side effects with a difference {'< 0.1%'} are omitted; a negative
            value indicates a reduced risk of the respective side effect.{' '}
            <Link href='/about'>Read more about our methodology.</Link>
          </div>
        </div>
        <LivingReviewTag />
      </div>
    </LoadingContainer>
  );
};

export default InterventionConditionAdverseEffectsSummary;

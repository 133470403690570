// Style overrides to match Pfizer branding
import { Theme } from '@mui/material/styles';

const BORDER_STYLE = '1px solid #bababa';

/** Container for loading states - CSS only */
export const PFIZER_LOADING_WRAPPER_STYLE = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column' as const,
  height: '67vh',
  p: { marginTop: '32px', textAlign: 'center' },
};

/** Overrides to OE default styling - MUI `sx` prop */
export const PFIZER_GLOBAL_STYLE = {
  background: 'none',
  '.MuiPaper-root': {
    borderRadius: 0,
    a: {
      color: 'primary.main',
      '&.MuiIconButton-root': {
        color: 'inherit !important',
      },
    },
  },
  '.MuiSnackbar-root': {
    borderRadius: 0,
  },
  '.brandable': {
    '&--wrapper': {
      mb: 8,
    },
    '&--action-bar': {
      borderTop: BORDER_STYLE,
      '&--flex-container': {
        justifyContent: 'center',
      },
    },
    '&--answer-description-badges': {
      '&--ai-generated svg': {
        color: '#90c28a',
      },
      '&--ai-quoted svg': {
        color: (theme: Theme) => theme.palette.secondary.main,
      },
    },
    '&--article-output': {
      backgroundColor: '#fcfcfc',
      border: BORDER_STYLE,
      boxShadow: 'none',
      pt: 1.5,
      px: 2,
      mt: 0,
      width: '100%',
    },
    '&--article-text': {
      lineHeight: '1.25',
    },
    '&--subconfig-toggle': {
      ml: 0,
      '.MuiToggleButton-root': {
        borderColor: '#cecece !important',
        borderRadius: 0,
        fontSize: '1rem',
        mt: 0,
      },
    },
    '&--custom-badge': {
      display: 'inline-block',
    },
    '&--faq-container': {
      mt: { xs: 8, sm: 12 },
      'a svg': {
        color: (theme: Theme) => `${theme.palette.primary.main} !important`,
      },
      'a:hover p, a:hover svg': {
        color: (theme: Theme) => `${theme.palette.secondary.main} !important`,
      },
    },
    '&--faq-title, &--faq-item p': {
      fontSize: '1rem',
    },
    // This is used to vertically align the text in each FAQ item with the > arrow symbol
    // The issue seems to be that the font used on the elsevier-poc is not vertically centered in its own viewbox
    '&--faq-item p': {
      marginBottom: '-4px',
    },
    '&--filter-select': {
      borderRadius: 0,
      margin: 0,
      height: '48px',
      alignSelf: 'center',
      pt: '2px',
      pr: '8px',
      background: 'none',
      '.MuiSelect-select': {
        pl: '4px',
        '&.Mui-disabled': {
          pr: '6px !important',
        },
      },
      '.MuiSelect-iconStandard': {
        right: '8px',
      },
    },
    // This is used to vertically align the text inside the filter selection dropdown in the search bar
    // The issue seems to be that the font used on the elsevier-poc is not vertically centered in its own viewbox
    '&--filter-selection': {
      paddingTop: '2px',
    },
    '&--follow-up-questions': {
      background: 'none',
      border: 'none',
      borderTop: BORDER_STYLE,
      pt: '20px',
      '> div': {
        padding: 0,
      },
      svg: {
        color: (theme: Theme) => theme.palette.primary.main,
      },
      'button:hover, button:hover svg': {
        color: (theme: Theme) => theme.palette.secondary.main,
        opacity: 1,
      },
      '&--title-bar': {
        svg: { display: 'none' },
        h2: { fontSize: '1.25rem' },
      },
    },
    '&--query-bar': {
      border: BORDER_STYLE,
      borderRadius: '0 !important',
      boxShadow: 'none',
      '&:focus-within': {
        boxShadow: 'none',
      },
      button: {
        borderRadius: '0 !important',
      },
      gap: 0,
      minHeight: 'unset',
      p: 0,
      '&--container': {
        maxWidth: 'unset',
      },
      '&--new-topic-button button': {
        borderRadius: 0,
      },
      '&--submit-button': {
        alignSelf: 'stretch',
        mb: 0,
        height: '52px !important',
      },
      '&--login-container': {
        position: 'static',
      },
      '&--login': {
        borderRadius: '0 !important',
        marginBottom: '0 !important',
      },
      '&--login-arrow': {
        display: 'none',
      },

      '&--input': {
        m: 0,
        padding: '8px 8px 8px',
        ':not(:has(.Mui-disabled))': {
          borderBottom: (theme: Theme) =>
            `2px solid ${theme.palette.primary.main}`,
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#000000',
        },
        '&::placeholder': {
          opacity: '70%',
        },
      },
      '&--answered-question': {
        width: '100%',
      },
      '&--label': {
        width: '100%',
      },
    },
    '&--references a, &--link': {
      transition: 'all .2s ease',
      '&:hover': {
        color: 'inherit !important',
        textDecoration: (theme: Theme) =>
          `underline ${theme.palette.secondary.main} 2px`,
      },
    },
  },
};

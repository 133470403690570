import DOMPurify from 'isomorphic-dompurify';
import { fixMarkdown } from '../../utils';
import { Box } from '@mui/material';
import styles from '../../index.module.css';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const LIGHT_BLUE_GREY = 'rgba(219, 237, 240, 0.95)';

interface BlockQuoteProps {
  text: string;
  citation_data: {
    full_citation_span: JSX.Element;
  };
}

/** Render the text as markdown in a blockquote. */
const BlockQuote = ({
  text,
  citation_data: { full_citation_span },
}: BlockQuoteProps) => {
  const sanitizedText = DOMPurify.sanitize(text, {
    FORBID_TAGS: ['a'],
  });

  let cleanedMarkdown = fixMarkdown(sanitizedText);

  // HACK ALERT!
  // We need to insert the citation at the end of the blockquote, but we can't insert
  // it as a child of <Markdown> because it only accepts the md string, and we also
  // can't serialize the citation element directly because it has callbacks associated
  // with it. Instead, we'll cheekily take advantage of the fact that we removed all
  // the links from the markdown, and insert a placeholder link that we can then
  // render as the citation. This should work for *most* cases we care about, but
  // I'm certain there are weird edge cases that will look weird with this, such as tables.
  cleanedMarkdown += '<a id="citation"></a>';

  const MaybeCitation = ({ id, children, ...props }: any) => {
    if (id === 'citation') {
      return full_citation_span;
    }
    return (
      <a id={id} {...props}>
        {children}
      </a>
    );
  };

  return (
    <Box
      className={`${styles.article_quote_style}`}
      sx={{
        padding: '0 1em ',
        borderLeft: `3px solid ${LIGHT_BLUE_GREY}`,
        marginLeft: '1em',
      }}
    >
      <Markdown
        rehypePlugins={[rehypeRaw]}
        components={{
          a: MaybeCitation,
        }}
      >
        {cleanedMarkdown}
      </Markdown>
    </Box>
  );
};

export default BlockQuote;

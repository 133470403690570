import styles from './TrialTags.module.css';

const getCitationsTagText = (citedByNum) => {
  if (citedByNum == null) {
    return 'Citations Unknown';
  }
  return `Known Citations: ${citedByNum}+`;
};

const getStudyTypeTagText = (study_type) => {
  return `Design: ${study_type}`;
};

const getJournalTagTextAndStyle = (journalHIndex) => {
  const textAndStyle = (journalTagText, journalTagStyle) => ({
    journalTagText,
    journalTagStyle,
  });
  const text = `Xyla Journal Score: ${journalHIndex}`;
  if (journalHIndex <= 15) {
    return textAndStyle(text, styles.tagLowQualityJournal);
  } else if (journalHIndex <= 40) {
    return textAndStyle(text, styles.tagMidTierJournal);
  } else {
    return textAndStyle(text, styles.tagTopJournal);
  }
};

const TrialTags = (props) => {
  const citationsTagText = getCitationsTagText(props.cited_by_num);
  const studyTypeTagText = getStudyTypeTagText(props.study_design_long);
  const { journalTagText, journalTagStyle } = getJournalTagTextAndStyle(
    props.journal_hindex
  );
  return (
    <div className={styles.trialTags}>
      {props.journal_hindex && (
        <div className={journalTagStyle}>{journalTagText}</div>
      )}
      {props.cited_by_num !== undefined && (
        <div className={styles.tagHighlyCited}>{citationsTagText}</div>
      )}
      {props.study_design_long && (
        <div className={styles.tagHighlyCited}>{studyTypeTagText}</div>
      )}
    </div>
  );
};

export default TrialTags;

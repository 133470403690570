import getArticleRichText from '../../../copypaste';
import { ArticleData } from '../../../types';
import { getUniqueCitations } from '../../../utils';
import { ActionButton } from './ActionButton';
import PrintIcon from '@mui/icons-material/Print';

export interface PrintButtonProps {
  article: ArticleData;
  fileName: string;
}

export const PrintButton = ({ article, fileName }: PrintButtonProps) => {
  const openPrintWindow = () => {
    const printWindow = window.open('', '_blank', 'width=800,height=600');

    const references = getUniqueCitations(article);
    const articleRichText = getArticleRichText(article, references);
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(`
          <html>
          <head>
              <title>${fileName}</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      padding: 20px;
                  }

                  /* Hide browser default header/footer during print */
                  @media print {
                      @page {
                          size: auto;
                          margin: 0;
                          -webkit-print-color-adjust: exact;  /* Ensure color accuracy */
                      }
                      body {
                          margin: 0;
                          padding: 1cm;
                      }
                  }
              </style>
          </head>
          <body>
              ${articleRichText}
              <script>
                (function() {
                  window.addEventListener('load', function() {
                    window.print();
                    window.close();
                  });
                })();
              </script>
          </body>
          </html>
      `);
      printWindow.document.close();
    }
  };

  return (
    <ActionButton
      tooltipText='Print'
      icon={<PrintIcon />}
      snackbarMessage='Opened print window'
      onClick={openPrintWindow}
    />
  );
};

import { SxProps } from '@mui/material/styles';
import {
  OE_ARTICLE_OUTPUT_MIN_HEIGHT,
  OE_STICKY_ASK_BAR_WIDTH,
} from './constants';

/** Overrides to OE default styling - MUI `sx` prop */
export function getOEGlobalStyle(): SxProps {
  return [
    {
      '*': {
        // Prevent awkward scrolls when opening "Show Details" accordions
        overflowAnchor: 'none',
      },
      '.brandable': {
        '&--action-bar--flex-container, &--faq-container': {
          maxWidth: OE_STICKY_ASK_BAR_WIDTH,
        },
        '&--article-output': {
          minHeight: OE_ARTICLE_OUTPUT_MIN_HEIGHT,
        },
        '&--decorated-bar': {
          ml: { xs: '10px', sm: 0 },
          width: { xs: 'calc(100% - 10px)', sm: '100%' },
          '&.brandable--article-input': {
            mt: 2,
          },
        },
        '&--citation': {
          color: 'primary.main',
        },
        '&--feedback-success-banner': {
          bgcolor: 'grey.900',
          color: '#fff',
          '& .MuiAlert-icon': { color: 'inherit' },
        },
        '&--follow-up-questions': {
          background: '#f8f8f8',
        },
        '&--stop-generation-button': {
          transform: 'none',
        },
        '&--query-bar': {
          minHeight: 'unset',
          pl: 2,
          '&--answered-question': {
            maxWidth: OE_STICKY_ASK_BAR_WIDTH,
            mx: 'auto',
          },
          '&--input': {
            my: { xs: '8px', sm: '14px' },
            pl: { xs: '4px', sm: '6px' },
          },
        },
        '&--references a': {
          // Using a string literal to refer to theme token doesn't work here, so we need the theme object.
          // Probably because we are targeting a native <a> element.
          color: (theme: any) => theme.palette.primary.main,
        },
        '&--wrapper': {
          mt: { xs: 1, sm: 2 },
        },
        '&--disclaimer-banner': {
          maxWidth: OE_STICKY_ASK_BAR_WIDTH,
          mx: 'auto',
          backgroundColor: 'grey.200',
          color: 'grey.900',
        },
      },
    },
  ];
}

'use client';

import article_styles from './index.module.css';

// Get specific article component possibilities
import deriveGeneratedArticle from './content/deriveGeneratedArticle';
import buildTitleAndDefinitions from './content/buildTitleAndDefinitions';
import { get_combined_styles } from './page_specific_css';
import ArticleHeader from './ArticleHeader';
import ArticleReferences from './ArticleReferences/ArticleReferences';
import TableOfContents from './TableOfContents';
import type { ArticleData } from './types';
import type { RenderFunctionFilter } from './content/spans';
import { PreReferencesFooter } from './PreReferencesFooter';
import { Box, Button, SxProps } from '@mui/material';
import VerbatimArticle from './VerbatimArticle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import getArticleRichText, { pasteRich } from './copypaste';
import { useArticleContext } from './contexts';
import { WebSearchResults } from './WebSearchResults/WebSearchResults';

const typesWithPreReferencesFooter = ['tldr_single_entry'];

interface ArticleProps {
  article: ArticleData;
  conjoined_page?: boolean;
  conjoined_page_first_page?: boolean;
  additionalComponentData?: Record<string, unknown>;
  additionalRenderFunctions?: Record<string, RenderFunctionFilter>;
  preHeaderElements?: JSX.Element[];
  preTextBodyElements?: JSX.Element[];
  postTextBodyElements?: React.ReactNode;
  postGeneratedArticleElements?: JSX.Element[];
  hideToC?: boolean;
  hideReviewedBy?: boolean;
  cursorSxFor?: (_location: string) => SxProps;
  shareUrl?: string;
  questionIndex?: number;
  conversationLength?: number;
  showCopyTextAndCitationsButton?: boolean;
}

export function Article({
  article,
  conjoined_page = false,
  conjoined_page_first_page = false,
  additionalComponentData = {},
  additionalRenderFunctions = {},
  preHeaderElements = [],
  preTextBodyElements = [],
  postTextBodyElements,
  postGeneratedArticleElements = [],
  hideToC = false,
  hideReviewedBy = false,
  cursorSxFor = (_location) => ({}),
  shareUrl = undefined,
  questionIndex,
  conversationLength,
  showCopyTextAndCitationsButton,
}: ArticleProps): JSX.Element {
  const { renderFeedbackButtonsBelowReferences } = useArticleContext();

  const styles = get_combined_styles(article_styles, article.page_type);
  const disableCitationLinks = conjoined_page || conjoined_page_first_page;
  const displayDisagree = false;

  const {
    byline,
    what_is_this_page_html: whatIsThisPage,
    dt_published: dtPublished,
    last_modified_date: lastModifiedDate,
    article_date: articleDate,
    page_type: pageType,
    cms_type: cmsType,
  } = article;
  const auditedBy =
    article.medically_reviewed_by ??
    article.medically_audited_by ??
    article.audited_by;

  const notableStudyData = {
    sections: undefined,
  };

  const numInterventions = article.intervention_as?.length ?? 0;
  const numConditions = article.condition_as?.length ?? 0;
  const showPreReferencesFooter =
    typesWithPreReferencesFooter.includes(cmsType);

  const { title, subtitle, definitions, linesToSkip } =
    buildTitleAndDefinitions(
      article,
      numInterventions,
      numConditions,
      pageType,
      styles
    );

  const { references, generatedArticle, sectionTitles, keyFindingsSection } =
    deriveGeneratedArticle({
      data: article,
      linesToSkip: linesToSkip,
      notableStudyData: notableStudyData,
      num_conditions: numConditions,
      num_interventions: numInterventions,
      styles: styles,
      displayDisagree: displayDisagree,
      disableCitationLinks: disableCitationLinks,
      additionalRenderFunctions: additionalRenderFunctions,
      additionalComponentData: additionalComponentData,
      smallVersion: false,
      cursorSxFor: cursorSxFor,
      questionIndex: questionIndex,
    });

  const externalSearchResults = article.external_search_results ?? [];

  const copyButton = (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        right: '-16px',
        display: 'flex',
        justifyContent: 'end',
        marginTop: '-18px',
      }}
    >
      <Button
        variant='outlined'
        startIcon={
          <ContentCopyIcon
            sx={{ color: 'inherit' }}
            style={{ fontSize: '14px' }}
          />
        }
        onClick={() => {
          const richText: string = getArticleRichText(article, references);
          pasteRich(richText, richText);
        }}
        sx={{
          fontSize: '14px',
          paddingY: '2px',
          paddingX: '10px',
          minWidth: 0,
          lineHeight: '16px',
          borderTop: 'none',
          borderRight: 'none',
          ':hover': { borderTop: 'none', borderRight: 'none' },
        }}
      >
        Copy
      </Button>
    </Box>
  );

  let articleBody;

  if (article.page_type === 'search_results') {
    articleBody = (
      <VerbatimArticle article={article} questionIndex={questionIndex} />
    );
  } else {
    articleBody = (
      <>
        {definitions}
        {generatedArticle}
        {showPreReferencesFooter && (
          <PreReferencesFooter
            auditedBy={auditedBy}
            dtPublished={dtPublished}
            lastModifiedDate={lastModifiedDate}
            articleDate={articleDate}
            styles={styles}
          />
        )}
        {postGeneratedArticleElements}
      </>
    );
  }

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.non_loader_content}>
        <div className={styles.full_article_wrapper_citeview}>
          {preHeaderElements}
          {!['search_results', 'article_card'].includes(article.page_type) && (
            <ArticleHeader
              byline={byline}
              title={title}
              subtitle={subtitle}
              dtPublished={dtPublished}
              lastModifiedDate={lastModifiedDate}
              articleDate={articleDate}
              keyFindingsSection={keyFindingsSection}
              auditedBy={auditedBy}
              pageType={pageType}
              whatIsThisPage={whatIsThisPage}
              leadImageUrl={article.lead_image_url}
              hideLeadImageInBody={article.hide_lead_image_in_body}
              conjoined_page={conjoined_page}
              styles={styles}
              hideReviewedBy={hideReviewedBy || showPreReferencesFooter}
              cursorSxFor={cursorSxFor}
              shareUrl={shareUrl}
            />
          )}
          <div className={styles.text_modal_wrapper}>
            {!hideToC && sectionTitles.length > 0 && (
              <TableOfContents
                pageType={pageType}
                references={references}
                sectionTitles={sectionTitles}
                conjoined_page={conjoined_page}
                conjoined_page_first_page={conjoined_page_first_page}
                styles={styles}
              />
            )}
            {preTextBodyElements}
            {showCopyTextAndCitationsButton && copyButton}

            <div className={`${styles.text_wrapper} brandable--article-text`}>
              {/* MAIN ARTICLE */}
              <div className={styles.concatenated_article}>{articleBody}</div>

              {/* By default, render feedback buttons before References */}
              {!renderFeedbackButtonsBelowReferences && postTextBodyElements}

              {/* References section */}
              <ArticleReferences
                references={references}
                styles={styles}
                pageType={pageType}
                questionIndex={questionIndex}
              />

              {/* External link section */}
              <WebSearchResults
                results={externalSearchResults}
                maxLinksCollapsed={
                  questionIndex !== undefined &&
                  conversationLength !== undefined &&
                  questionIndex === conversationLength - 1
                    ? 3
                    : 0
                }
              />

              {/* If this flag is set, render feedback buttons here */}
              {renderFeedbackButtonsBelowReferences && postTextBodyElements}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { deriveGeneratedArticle, article_styles };

export {
  VerticalBarChart,
  VerticalBarChartWithParameters,
  parseTitleString,
} from './content/article_components/VerticalBarChart';

export { InterventionConditionEffectSizeFigure } from './content/article_components/InterventionConditionEffectSizeFigure';

export { default as EmbeddedLinkedIn } from './content/article_components/EmbeddedLinkedIn';
export { default as EmbeddedTweet } from './content/article_components/EmbeddedTweet';
export { default as EmbeddedYouTube } from './content/article_components/EmbeddedYouTube';

export { create_citation_span } from './citations';
export {
  slugify,
  combineStyles,
  DEFAULT_ARTICLE_DATA,
  getArticleDescription,
  getRawText,
  getDatePublished,
  getDateUpdated,
  parseArticleDate,
  formatArticleDate,
  hasDateAvailable,
} from './utils';

export * from './types';
export type { RenderFunctionFilter } from './content/spans';

export { default as useArticleDataTypewriter } from './typewriter/useArticleDataTypewriter';
export {
  default as useTextTypewriter,
  cursorBlinkStyle,
} from './typewriter/useTextTypewriter';
export type { SplitType } from './typewriter/useTextTypewriter';
export type { UseTypewriterTimings } from './typewriter/types';

export { default as SocialTray } from './SocialTray';

export { ElsevierIcon } from './ArticleReferences/ElsevierIcon';

export {
  default as LoadingContainer,
  useLoadingContainer,
} from './LoadingContainer';
export * from './contexts';

export {
  default as ArticlePlaceholder,
  DEFAULT_STEPS,
} from './ArticlePlaceholder';

export type { Step } from './ArticlePlaceholder';

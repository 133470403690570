const COLOR_DEFS = {
  HEADER_ORANGE: '#d09b57',
  LOGO_ORANGERED: '#0A0A0A',
  NEW_LOGO_ORANGERED: '#e9714c', // in newest OE logo
  SLIGHTLY_DARKER_THAN_INFOBOX_BACKGROUND: 'rgb(229, 230, 219)',
};

const COLORS = {
  // The main signaling color
  // MAIN_SIGNAL : '#c06d4a',
  // MAIN_SIGNAL : '#c6704c',
  MAIN_SIGNAL: COLOR_DEFS.LOGO_ORANGERED,

  // When there is no data (i.e. in remaining part of pie chart)
  MISSING_PIE: COLOR_DEFS.SLIGHTLY_DARKER_THAN_INFOBOX_BACKGROUND,

  // Color for a background signal (placebo)
  BACKGROUND_SIGNAL: '#ccccaa',

  // For bar and shape outlines.
  OUTLINES: '#bbbbbb',

  // Color of errorbars
  ERRORBARS: '#333333',

  // Gray that fades into the background - eg for grid
  GRID_LINES: '#cccccc',

  // Best orange for branding the site
  BRAND: COLOR_DEFS.NEW_LOGO_ORANGERED,
};

// Previous colors:
// Small effect size graph:
// - bar fill: '#d09b57'
// - grid: '#bbbbbb'
// Adverse effect graph:
// - bar fill: '#ee7700'
// -

export default COLORS;

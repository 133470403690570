import { ArticleData } from '../types';
import Divider from '@mui/material/Divider';
import SourceCard from './SourceCard';
import Box from '@mui/material/Box';

interface VerbatimArticleProps {
  article: ArticleData;
  questionIndex?: number;
}

export default function VerbatimArticle({
  article,
  questionIndex,
}: VerbatimArticleProps) {
  return (
    <Box
      sx={{
        paddingTop: '12px',
        paddingBottom: '24px',
      }}
    >
      {article.articlesection_set.map((section) => {
        return section.articleparagraph_set.map((paragraph, i) => (
          <div key={`full_${questionIndex}_${i}`}>
            <SourceCard paragraph={paragraph} paragraphIndex={i} />
            {i < section.articleparagraph_set.length - 1 && (
              <Divider
                sx={{
                  width: '100%',
                  my: 2,
                }}
              />
            )}
          </div>
        ));
      })}
    </Box>
  );
}

import { RATING_LABELS } from '@xyla/util';
import { CitationScores } from '../types';

const SHOW_BADGES = {
  NO_BADGES: 3,
  HIGH_BADGE_AND_ABOVE: 2,
  MEDIUM_BADGE_AND_ABOVE: 1,
};
// If the rating is below this value, we will hide it in the UI.
// The value refers to the "lightweight enum" value above
const RATING_UI_THRESHOLD: { [key: string]: number } = {
  impact: SHOW_BADGES.HIGH_BADGE_AND_ABOVE,
  recency: SHOW_BADGES.HIGH_BADGE_AND_ABOVE,
  relevance: SHOW_BADGES.HIGH_BADGE_AND_ABOVE,
};

interface RatingThreshold {
  [key: string]: {
    high: number;
    medium: number;
  };
}

interface RatingThresholds {
  [key: string]: RatingThreshold;
}

const RATING_THRESHOLDS: RatingThresholds = {
  impact: {
    official_impact_factor: {
      high: 6.62, // 92nd percentile
      medium: 3.69, // 76th percentile
    },
  },
  relevance: {
    retrieval_score_percentile_rank: {
      // high: 99.999,
      high: 99.99,
      medium: Infinity,
    },
  },
  recency: {
    days_since_published: {
      high: 365,
      medium: 365 * 3,
    },
  },
};

export interface CitationRating {
  type: string;
  rating: number;
  label: string;
}

/**
 * Buckets citation scores into low/medium/high ratings and returns
 * an array to show in the UI, ordered by rating.
 * We won't show any ratings which fall below the RATING_UI_THRESHOLD.
 */
export function calculateRatings(scores?: CitationScores): CitationRating[] {
  if (!scores) {
    return [];
  }
  const relevanceRating = calculateSingleRating(
    'relevance',
    scores.relevance_score,
    scores.relevance_score_type,
    (a, b) => a >= b
  );
  const impactRating = calculateSingleRating(
    'impact',
    scores.impact_score,
    scores.impact_score_type,
    (a, b) => a >= b
  );
  // NB(andy): Recency is a less-than threshold
  const recencyRating = calculateSingleRating(
    'recency',
    scores.recency_score,
    scores.recency_score_type,
    (a, b) => a <= b
  );

  const result: CitationRating[] = [];
  if (scores.is_review) {
    result.push({ type: 'is_review', rating: 1, label: 'Review' });
  }
  if (relevanceRating.rating >= RATING_UI_THRESHOLD.relevance) {
    result.push(relevanceRating);
  }
  if (impactRating.rating >= RATING_UI_THRESHOLD.impact) {
    result.push(impactRating);
  }
  if (recencyRating.rating >= RATING_UI_THRESHOLD.recency) {
    result.push(recencyRating);
  }
  return result;
}

function calculateSingleRating(
  ratingType: string,
  score: number | undefined,
  scoreType: string | undefined,
  compareFn: (a: any, b: any) => boolean
): CitationRating {
  let rating = 0;
  // Only run calculations if score exists
  if (typeof score === 'number' && typeof scoreType === 'string') {
    if (compareFn(score, RATING_THRESHOLDS[ratingType][scoreType]?.high ?? 0)) {
      rating = 2;
    } else if (
      compareFn(score, RATING_THRESHOLDS[ratingType][scoreType]?.medium ?? 0)
    ) {
      rating = 1;
    }
  }

  return {
    type: ratingType,
    rating: rating,
    label: RATING_LABELS[ratingType][rating],
  };
}

// Style overrides for Bison branding
const BORDER_STYLE = '1px solid #bababa';

/** Overrides to OE default styling - MUI `sx` prop */
export const FINANCE_GLOBAL_STYLE = {
  '.MuiPaper-root': {
    border:
      'none' /* This gets rid of sidebar border; unclear where that border is set in the first place though */,
  },
  '.brandable': {
    '&--question-history': {
      '&--empty-state': {
        color: '#FFFFFF',
      },
      '&--logged-out-state': {
        color: '#FFFFFF !important',
      },
      '&--logged-out-state-button': {
        color: '#FFFFFF',
        border: '1px solid #FFFFFF',
        '&:hover': {
          border: '1px solid #FFFFFF',
          backgroundColor: '#333333',
        },
      },
      '&--container': {
        backgroundColor: '#0A0A0A',
      },
      '&--divider': {
        display: 'none',
      },
      '&--menu-icon': {
        svg: {
          color: '#FFFFFF', // Replace with your desired color
        },
      },
      '&--profile': {
        backgroundColor: '#0A0A0A',
        color: '#FFFFFF',
        border: 'none',
      },
      '&--load-more-questions': {
        backgroundColor: '#0A0A0A',
        color: '#FFFFFF',
        border: BORDER_STYLE,
        '&:hover': {
          border: '1px solid #FFFFFF',
        },
      },
      '&--list-item': {
        borderRadius: '8px',
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#222222',
        },
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          width: '6px' /* Set the width of the stick */,
          backgroundColor: 'orange' /* Set the color of the stick */,
          borderRadius: '6px' /* Set border-radius to create rounded corners */,
          opacity: 0,
        },
        '&-selected': {
          backgroundColor: '#222222', // Set your desired background color for selected items
          '&::before': {
            opacity: 1, // Always show the orange bar for selected items
          },
        },
      },
      '&--time': {
        color: '#808080',
      },
      '&--list-container': {
        paddingX: '12px',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      '&--new-conversation': {
        color: '#FFFFFF',
        backgroundColor: '#282828',
        border: '1px solid #363636',
        width: 'auto',
        height: '34px',
        '&:hover': {
          backgroundColor: '#363636',
        },
      },
    },
    '&--layout': {
      // backgroundColor: '#0A0A0A',
      border: BORDER_STYLE,
      // flex: '1 0 auto',
      background: '#FFFFFF',
      maxWidth:
        '100%' /* TODO: Unclear why this is necessary, max-width: 1200px isn't anywhere in code but inspect element shows it's set */,
      borderRadius: '6px',
      height: `calc(100vh - 75px)`,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '&--query-bar': {
      border: '1.5px solid #000000 !important',
      boxShadow: 'none !important',
      '&--input': {
        boxShadow: 'none !important',
      },
      '&--container.follow-up': {
        marginBottom: '15px',
      },
      '&--login': {
        borderRadius: '6px',
      },
    },
    '&--query-bar, &--query-bar--submit-button, &--filter-select': {
      borderRadius: '6px',
    },
    '&--article-output': {
      boxShadow: 'none',
    },
    '&--faq-container': {
      background: '#F5F5F5',
      borderRadius: '15px',
      padding: '20px',
    },
    '&--follow-up-questions': {
      background: '#F5F5F5',
      borderRadius: '15px',
      border: '1px solid black',
      '&--chevron-right': {
        color: '#000000',
      },
    },
  },
};

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ActionButton } from './ActionButton';
import getArticleRichText, { pasteRich } from '../../../copypaste';
import { ArticleData } from '../../../types';
import { getUniqueCitations } from '../../../utils';

export interface CopyToClipboardProps {
  article: ArticleData;
}

export const CopyToClipboardButton = ({ article }: CopyToClipboardProps) => {
  const copyRichText = () => {
    const references = getUniqueCitations(article);
    const richText = getArticleRichText(article, references);
    pasteRich(richText, richText);
  };

  return (
    <ActionButton
      tooltipText='Copy to clipboard'
      icon={<ContentCopyIcon />}
      onClick={copyRichText}
      snackbarMessage='Copied to clipboard'
    />
  );
};

import parse from 'html-react-parser';

import { ArticleData } from '../types.js';

export interface ArticleDerivedSections {
  title: string | JSX.Element | JSX.Element[];
  subtitle?: string | JSX.Element | JSX.Element[];
  definitions?: JSX.Element;
  linesToSkip: number;
}

function buildInterventionConditionSections(
  data: ArticleData,
  styles: Record<string, string>
): ArticleDerivedSections {
  // First, find the locations of the cluster names in the title and separate them out
  let i_index = data.title.indexOf(data.intervention_as[0].name_for_writing);
  let i_length = data.intervention_as[0].name_for_writing.length;
  let c_index = data.title.indexOf(data.condition_as[0].name_for_writing);
  let c_length = data.condition_as[0].name_for_writing.length;
  let title_components = [
    data.title.substring(0, i_index),
    data.title.substring(i_index, i_index + i_length),
    data.title.substring(i_index + i_length, c_index),
    data.title.substring(c_index, c_index + c_length),
    data.title.substring(c_index + c_length),
  ];

  let title_spans = [];
  for (let i = 0; i < title_components.length; i++) {
    let title_string = title_components[i];
    title_spans.push(<span key={i}>{parse(title_string)}</span>);
  }

  // Build the definition paragraphs
  let defn_paragraphs = data.articlesection_set[1].articleparagraph_set;

  // If something is wrong, just proceed without doing anything special
  if (defn_paragraphs.length !== 2) {
    return { linesToSkip: 0, title: <>{title_spans}</> };
  }

  // Build definition paragraphs that can handle being expanded and collapsed
  let int_defn_text =
    data.articlesection_set[1].articleparagraph_set[0].articlespan_set[0].text;
  let int_defn_paragraph = (
    <div
      id={'intDefnParagraph'}
      className={styles.defn_paragraph_div}
      key={`int_defn_paragraph`}
    >
      <div className={'defnParagraphInner'}>
        <div className={styles.defn_paragraph_p}>
          <span>{int_defn_text}</span>
        </div>
      </div>
    </div>
  );

  let cond_defn_text =
    data.articlesection_set[1].articleparagraph_set[1].articlespan_set[0].text;
  let cond_defn_paragraph = (
    <div
      id={'condDefnParagraph'}
      className={styles.defn_paragraph_div}
      key={`cond_defn_paragraph`}
    >
      <div className={'defnParagraphInner'}>
        <div className={styles.defn_paragraph_p}>
          <span>{cond_defn_text}</span>
        </div>
      </div>
    </div>
  );

  return {
    title: <>{title_spans}</>,
    definitions: (
      <div className={styles.defn_section} key={'defn_section'}>
        <div className={styles.defn_section_body}>
          {[int_defn_paragraph, cond_defn_paragraph]}
        </div>
      </div>
    ),
    linesToSkip: 1,
  };
}

function buildDefaultArticleSections(
  data: ArticleData
): ArticleDerivedSections {
  let title_str = '';
  let subtitle_str = '';

  let subtitle_sep = '';
  let remove_sep = true;

  if (data.title.includes(':')) {
    subtitle_sep = ':';
  } else if (data.title.includes('—')) {
    subtitle_sep = '—';
  } else if (data.title.includes('?')) {
    subtitle_sep = '?';
    remove_sep = false;
  }

  let extra_remove = remove_sep ? 1 : 0;

  if (subtitle_sep !== '') {
    let subtitle_start_idx = data.title.indexOf(subtitle_sep);
    title_str = data.title
      .substring(0, subtitle_start_idx + 1 - extra_remove)
      .trim();
    subtitle_str = data.title.substring(subtitle_start_idx + 1).trim();
  } else {
    title_str = data.title;
  }
  return {
    title: parse(title_str),
    subtitle: parse(subtitle_str),
    linesToSkip: 0,
  };
}

export default function buildTitleAndDefinitions(
  data: ArticleData,
  numInterventions: number,
  numConditions: number,
  pageType: string,
  styles: Record<string, string>
): ArticleDerivedSections {
  // If it's a i+c article, then do the special formatting
  if (
    numInterventions === 1 &&
    numConditions === 1 &&
    pageType !== 'condition_subpage'
  ) {
    return buildInterventionConditionSections(data, styles);
  } else if (pageType.includes('ask_oe')) {
    return {
      title: parse(data.title),
      subtitle: '',
      linesToSkip: 0,
    };
  } else {
    return buildDefaultArticleSections(data);
  }
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import styles from './ArticleReview.module.css';
import useTextTypewriter, {
  cursorBlinkStyle,
} from '../../typewriter/useTextTypewriter';
import { ResetType } from '../../typewriter/types';

interface ArticleReviewData {
  text: string;
  bullets?: string[];
  hideArticleReview?: boolean; // Set in TLDrFeedSmallEntry.tsx
}

const LIGHT_TEXT = '#ddd';
const HEADER_TEXT_SIZE = 16;
const DARK_STYLE = {
  background: '#333',
  color: LIGHT_TEXT,
};

const ArticleReviewHeader = () => (
  <Box display='flex' flexDirection='row' alignItems='center'>
    <Typography fontSize={HEADER_TEXT_SIZE}>OpenEvidence Analysis</Typography>
  </Box>
);

const boldBeforeColon = (text: string) => {
  if (!text.includes(':')) {
    return text;
  }

  const split = text.split(':');
  return (
    <>
      <b>{split[0]}:</b>
      {split.slice(1).join(':')}
    </>
  );
};

const IntroBulletsContent = ({
  bullets,
  running,
  display,
}: {
  bullets: string[];
  running: boolean;
  display: boolean;
}) => (
  <Box
    component='ul'
    sx={{
      visibility: display ? 'visible' : 'hidden',
      position: display ? 'absolute' : 'static',
      left: 0,
      top: 0,
      m: 0,
      p: 0,
    }}
  >
    {bullets.map((bullet, i) => (
      <Typography
        key={i}
        component='li'
        sx={i < bullets.length - 1 ? { mb: 2 } : cursorBlinkStyle(running)}
      >
        {boldBeforeColon(bullet)}
      </Typography>
    ))}
  </Box>
);

interface IntroBulletsProps {
  bullets: string[];
}

const IntroBullets = ({ bullets }: IntroBulletsProps) => {
  const {
    running,
    done,
    textWritten,
    setTextToWrite,
    startTypewriter,
    stopTypewriter,
  } = useTextTypewriter(
    {
      delayMsStart: 3,
      delayMsEnd: 5,
      longWaitFreq: 1 / 50,
      longWaitDelayMsStart: 20,
      longWaitDelayMsEnd: 80,
      newlineDelayMsStart: 2000,
      newlineDelayMsEnd: 2000,
      initialDelayMs: 1500,
    },
    'character'
  );

  const ref = useRef<HTMLElement>();

  useEffect(() => {
    setTextToWrite(bullets, ResetType.All);
  }, [setTextToWrite, bullets]);

  const inViewChange = (inView: boolean) => {
    if (!done) {
      if (inView) {
        startTypewriter();
      } else {
        stopTypewriter();
      }
    }
  };

  return (
    <InView onChange={inViewChange}>
      <Box ref={ref} sx={{ ...DARK_STYLE, m: 0, px: 4, pt: 2, pb: 1 }}>
        <Box position='relative'>
          <IntroBulletsContent
            bullets={bullets}
            running={false}
            display={false}
          />
          <IntroBulletsContent
            bullets={textWritten}
            running={running}
            display={true}
          />
        </Box>
      </Box>
    </InView>
  );
};

const ArticleReview = ({ data }: { data: ArticleReviewData }) => {
  const {
    textWritten,
    running,
    setTextToWrite,
    startTypewriter,
    typewriterWriteAll,
    stopTypewriter,
  } = useTextTypewriter(
    {
      delayMsStart: 3,
      delayMsEnd: 5,
      longWaitFreq: 1 / 50,
      longWaitDelayMsStart: 20,
      longWaitDelayMsEnd: 80,
      newlineDelayMsStart: 2000,
      newlineDelayMsEnd: 2000,
    },
    'character'
  );

  const textToWrite = data.text.split('\n\n');

  const [numTimesOpened, setNumTimesOpened] = useState(0);

  if (data.hideArticleReview) {
    // We can't return null here because of a subtle interaction with how our nested components call useArticleContext.
    // If we return null, then that hook won't get called, violating the rules of hooks.
    return <></>;
  }

  const accordianChange = (event: React.SyntheticEvent, expanded: boolean) => {
    event.stopPropagation();
    if (!expanded) {
      stopTypewriter();
      return;
    }
    if (numTimesOpened >= 2) {
    } else if (numTimesOpened === 1) {
      typewriterWriteAll();
    } else {
      setTextToWrite(textToWrite, ResetType.All);
      startTypewriter();
    }
    setNumTimesOpened(numTimesOpened + 1);
  };

  const ExpandedAnalysis = () => (
    <Box px={2} py={2}>
      {textWritten.map((line, i) => (
        <Typography
          key={i}
          component='p'
          sx={{
            mb: 1.5,
            ...(i === textWritten.length - 1 ? cursorBlinkStyle(running) : {}),
          }}
        >
          {line}
        </Typography>
      ))}
    </Box>
  );

  return data.bullets ? (
    <Paper sx={{ my: 4 }}>
      <Box px={2} py={1.2}>
        <ArticleReviewHeader />
      </Box>
      <IntroBullets bullets={data.bullets} />
      <Accordion
        disableGutters
        elevation={0}
        onChange={accordianChange}
        classes={{
          root: styles.MuiAccordionRootBullets,
        }}
        sx={{
          mt: '0px',
          '&::before': { backgroundColor: '#00000000' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor={LIGHT_TEXT} />}
          aria-controls='expanded-analysis'
          sx={{ ...DARK_STYLE }}
        >
          <Box width='100%' textAlign='center'>
            <Typography fontSize={HEADER_TEXT_SIZE - 3}>
              Detailed Analysis
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ ...DARK_STYLE, pt: 0 }}>
          <ExpandedAnalysis />
        </AccordionDetails>
      </Accordion>
    </Paper>
  ) : (
    <Box>
      <Accordion onChange={accordianChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='expanded-analysis'
        >
          <ArticleReviewHeader />
        </AccordionSummary>
        <AccordionDetails sx={DARK_STYLE}>
          <ExpandedAnalysis />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default ArticleReview;
